<template>
  <div class="flex items-center">
    <template v-if="patient">
      <div class="w-12 p-2">
        <patient-photo
          class="ml-1"
          :zis_number="patient.zis_number"
        ></patient-photo>
      </div>
      <div class="flex min-w-0 flex-grow flex-col items-start pt-2 md:pt-0">
        <div class="max-w-full items-center gap-x-2 md:flex">
          <div class="hidden truncate md:block">{{ fullName(patient) }}</div>
          <div class="block truncate md:hidden">
            {{ fullNameInitials(patient) }}
          </div>
          <div class="min-w-fit">
            <GenderIconComponent :gender="patient.gender" />
          </div>
          <span
            class="pl-2 text-sm text-gray-500 group-hover:text-gray-600"
            v-if="patient.date_of_birth"
            >{{ format(patient.date_of_birth, "dd-MM-y") }}</span
          >
          <div v-if="!patientSpeaksDutch">
            <div class="space-x-1">
              <div
                class="font-regular inline-flex flex-row justify-between gap-1 rounded-full bg-gradient-to-br from-blue-200 to-blue-400 px-2.5 py-0.5 text-xs shadow-sm"
                v-for="language in foreignLanguagesOfPatient"
              >
                {{ language }}
              </div>
            </div>
          </div>
        </div>
        <span class="text-xs text-slate-700 group-hover:text-slate-800">{{
          appointment.referral?.hcp_diagnosis || "-"
        }}</span>
      </div>
    </template>
    <div
      class="box-border flex min-w-0 items-center space-x-4 py-2 pl-4"
      v-else
    >
      <span class="text-4xl">
        <i class="fal fa-calendar-alt" aria-hidden="true" />
      </span>
      <span class="truncate md:overflow-auto md:whitespace-normal">
        {{ appointment.description }}
      </span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, PropType } from "vue";
import GenderIconComponent from "../GenderIcon.vue";
import {
  AppointmentApi,
  PatientApi,
} from "@/libraries/repositories/appointmentRepositoryUsingApi";
import { format } from "date-fns";
import { fullName, fullNameInitials } from "../../models/Person";

const props = defineProps({
  appointment: {
    type: Object as PropType<AppointmentApi>,
    required: true,
  },
  patient: {
    default: null,
    type: Object as PropType<PatientApi> | null,
  },
});

const patientSpeaksDutch = computed(() => {
  const patientLanguage = props.patient.languages;
  if (!patientLanguage) {
    return true;
  }
  if (patientLanguage.length == 0) {
    return true;
  }
  if (patientLanguage.map((x) => x.code).includes("nl")) {
    return true;
  }

  return false;
});

const foreignLanguagesOfPatient = computed(() => {
  const patientLanguage = props.patient.languages;

  return patientLanguage?.map((x) => x.communication_language_nl) ?? [];
});
</script>
