<template>
  <flux-tooltip
    v-if="showUpdateNotification"
    :content="$t('header.newUpdate')"
    placement="bottom"
  >
    <div
      class="group mt-1 flex h-8 w-8 cursor-pointer items-center justify-center rounded text-center transition duration-75 hover:bg-slate-100"
    >
      <flux-badge color="bg-rose-500">
        <svg
          class="h-6 w-6 text-slate-800 group-hover:text-slate-900"
          @click="openReleaseNotes()"
          aria-hidden="true"
          focusable="false"
          data-prefix="fad"
          data-icon="gift"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <g class="fa-group">
            <path
              class="fa-secondary"
              fill="currentColor"
              d="M224 320v160H64a32 32 0 0 1-32-32V320zm0-160H32a32 32 0 0 0-32 32v80a16 16 0 0 0 16 16h208zm256 0H288v128h208a16 16 0 0 0 16-16v-80a32 32 0 0 0-32-32zM288 480h160a32 32 0 0 0 32-32V320H288z"
              opacity="0.4"
            ></path>
            <path
              class="fa-primary"
              fill="currentColor"
              d="M224 480h64V320h-64zM359.54 32c-41.82 0-68.86 21.3-103.54 68.3-34.68-47-61.72-68.3-103.54-68.3C103.71 32 64 71.5 64 120a85.65 85.65 0 0 0 10.15 40h78.21a40 40 0 1 1 0-80c20 0 34.78 3.3 86.55 80H224v128h64V160h-15c51.67-76.5 66-80 86.55-80a40 40 0 1 1 0 80h78.31A86.86 86.86 0 0 0 448 120c0-48.5-39.71-88-88.46-88z"
            ></path>
          </g>
        </svg>
      </flux-badge>
    </div>
  </flux-tooltip>
</template>

<script lang="ts" setup>
import { useRouter } from "vue-router";
import { telemetryManager } from "@/libraries/telemetry/Manager";
import { hasUnreadReleaseNotes } from "./ReleaseNotes/posts";

const router = useRouter();
const showUpdateNotification = hasUnreadReleaseNotes();

function openReleaseNotes() {
  telemetryManager.queueEntry({
    action: "releasenotes.open.updatenotification",
  });
  router.push("/releasenotes").catch(() => {});
}
</script>
