<template>
  <div
    class="mx-auto box-border w-full max-w-[100vw] md:max-w-[calc(100vw-6.5rem)] lg:max-w-screen-lg"
    v-flux-loading="state === 'loading'"
    @mouseenter="addListener"
    @mouseleave="
      () => {
        removeListener();
        showOverlay = false;
      }
    "
  >
    <div
      class="mb-3 flex items-center justify-between border-b border-gray-200 px-2 pb-5 md:px-0"
    >
      <h1 class="m-0 hidden text-2xl font-normal sm:block">{{ greeting }}</h1>
      <h1 class="m-0 block text-xl font-normal sm:hidden">
        {{ greetingWithoutName }}
      </h1>
      <flux-button
        class="inline-flex"
        size="small"
        @click="$router.push('patient/create')"
        type="primary"
        icon="fal fa-plus"
      >
        {{ $t("patient.create.button") }}
      </flux-button>
    </div>
    <div class="relative" v-if="state !== 'empty'">
      <transition
        enter-active-class="transition transform duration-200 ease-out"
        enter-from-class="-translate-x-2 opacity-0"
        enter-to-class="scale-100 opacity-100"
        leave-active-class="absolute transition transform duration-200 ease-out"
        leave-from-class="scale-100 opacity-100"
        leave-to-class="translate-x-2 opacity-0"
      >
        <div
          class="sticky right-1 top-1 z-50"
          v-if="showOverlay"
          v-flux-loading="awaitOverlayDebounce"
        >
          <flux-card
            class="pointer-events-none absolute right-0 top-0 z-50 w-full max-w-lg"
            v-if="overlayAppointment && locations"
          >
            <AppointmentLog
              class="ml-5 mt-3"
              :appointment="overlayAppointment"
              :locations="locations"
            />
          </flux-card>
        </div>
      </transition>
      <div class="mx-2 md:mx-0">
        <div class="space-y-4">
          <div
            v-for="appointment in activeAppointments"
            @mouseover="hoveringAppointment = appointment"
            @mouseleave="hoveringAppointment = undefined"
          >
            <DashboardEntry
              :key="appointment.id"
              :appointment="appointment"
              :appointmentTypes="appointmentTypes"
              :patientZisMap="patientZisMap"
              @appointmentStatusChange="
                (appointment, status) =>
                  handleAppointmentStatusChange(appointment, status)
              "
              @showEvents="toggleShowEvents(appointment)"
            />
          </div>
        </div>
        <div class="mt-8" v-if="completedAppointments.length > 0">
          <div class="flex justify-center text-sm">
            {{
              completedAppointments.length.toString() +
              " " +
              (completedAppointments.length === 1
                ? "voltooide afspraak"
                : "voltooide afspraken")
            }}
          </div>
          <div class="mt-2 space-y-4">
            <div
              v-for="appointment in completedAppointments"
              @mouseover="hoveringAppointment = appointment"
              @mouseleave="hoveringAppointment = undefined"
            >
              <DashboardEntry
                :key="appointment.id"
                :appointment="appointment"
                :appointmentTypes="appointmentTypes"
                :patientZisMap="patientZisMap"
                @appointmentStatusChange="
                  (appointment, status) =>
                    handleAppointmentStatusChange(appointment, status)
                "
                @showEvents="toggleShowEvents(appointment)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col items-center" v-else-if="state === 'empty'">
      <img
        src="@/assets/images/events.svg"
        style="width: 100%; max-width: 300px"
      />
      <div class="mt-4">
        {{ $t("calendar.no_appointments") }}
      </div>
      <div class="mt-4">
        <flux-button
          type="primary"
          @click="$router.push('calendar')"
          icon="fal fa-plus"
          >{{ $t("calendar.create_appointment") }}</flux-button
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { inject, onBeforeUnmount, onMounted, ref, watch } from "vue";
import DashboardEntry from "../Dashboard/DashboardEntry.vue";
import { getGreeting } from "@/actions/getGreeting";
import { AppointmentStatus, AppointmentType } from "@/models/Appointment";
import { AppointmentRepositoryKey } from "@/libraries/repositories/appointmentRepository";
import {
  AppointmentApi,
  AppointmentRepositoryBody,
  AppointmentRepositoryResponse,
  PatientApi,
} from "@/libraries/repositories/appointmentRepositoryUsingApi";
import { getUser } from "@/libraries/plugins/getUser";
import { format } from "date-fns";
import store, { pinia } from "@/libraries/store";
import { debounce } from "debounce";
import AppointmentLog from "./Appointment/AppointmentLog.vue";
import { useLocationStore } from "@/libraries/store/Locations";
import { Location } from "@/models/Location";
import { useAppointmentTypeStore } from "@/libraries/store/AppointmentTypes";
import { useRouter } from "vue-router";

type TodayWidgetState = "loading" | "loaded" | "empty";

const state = ref<TodayWidgetState>("loaded");
const activeAppointments = ref<AppointmentApi[]>([]);
const completedAppointments = ref<AppointmentApi[]>([]);
const appointmentTypes = ref<AppointmentType[] | undefined>(undefined);
const greeting = ref<string>(getGreeting(getUser()));
const greetingWithoutName = ref<string>(getGreeting());
const patientZisMap = ref<Map<number, PatientApi>>(new Map());
const locations = ref<Location[]>();
const awaitOverlayDebounce = ref(false);

const appointmentTypeStore = useAppointmentTypeStore(pinia);
const locationStore = useLocationStore(pinia);
const router = useRouter();

const appointmentRepository = inject(AppointmentRepositoryKey)?.();
if (!appointmentRepository) {
  throw new Error("Appointment injection failure");
}

onMounted(async () => {
  state.value = "loading";
  const res = await fetchAppointmentData();
  const appointments = res.appointments.filter(
    ({ status }) => status !== "PROPOSED" && status !== "CANCELLED",
  );
  if (appointments.length === 0) {
    state.value = "empty";
    return;
  }
  groupAndSortAppointments(appointments);
  setPatientMap(res.patients ?? []);
  setAppointmentTypes();
  locations.value = await locationStore.findAll();
  state.value = "loaded";
});

const fetchAppointmentData = async () => {
  const todayString = format(new Date(), "y-MM-dd");
  return await appointmentRepository.findAll({
    start: todayString,
    end: todayString,
    user_ids: [getUser().id],
  });
};

const setPatientMap = (patients: PatientApi[]) => {
  patients.forEach((patient) =>
    patientZisMap.value.set(patient.zis_number, patient),
  );
};

const setAppointmentTypes = async () => {
  appointmentTypes.value = await appointmentTypeStore.findAll();
};

const groupAndSortAppointments = (apps: AppointmentRepositoryResponse) => {
  activeAppointments.value = apps
    .filter((appointment) => appointmentIsActive(appointment))
    .sort((a, b) => (a.start < b.start ? -1 : 1));
  completedAppointments.value = apps
    .filter((appointment) => !appointmentIsActive(appointment))
    .sort((a, b) => (a.start < b.start ? -1 : 1));
};

const handleAppointmentStatusChange = async (
  appointment: AppointmentRepositoryBody,
  status: AppointmentStatus,
) => {
  state.value = "loading";
  await appointmentRepository.updateStatus(appointment, status, router);
  const res = await fetchAppointmentData();
  groupAndSortAppointments(res.appointments);
  state.value = "loaded";
  await store.dispatch("calendar/reloadAppointment", appointment.id);
};

const appointmentIsActive = (appointment: AppointmentApi) => {
  return appointment.status === "BOOKED" || appointment.status === "ARRIVED";
};

function toggleShowEvents(appointment: AppointmentApi) {
  if (showOverlay.value) {
    showOverlay.value = false;
    overlayAppointment.value = undefined;
    return;
  }
  showOverlay.value = true;
  overlayAppointment.value = appointment;
}

const hoveringAppointment = ref<AppointmentApi>();
watch(hoveringAppointment, () => {
  debounce(setOverlayAppointment, 400)();
});
function addListener() {
  window.addEventListener("keydown", onKeydown);
}

function removeListener() {
  window.removeEventListener("keydown", onKeydown);
}

const showOverlay = ref(false);
function onKeydown(event: KeyboardEvent) {
  if (event.key === " ") {
    showOverlay.value = !showOverlay.value;
    event.preventDefault();
  }
}

const overlayAppointment = ref<AppointmentApi>();
function setOverlayAppointment() {
  overlayAppointment.value = hoveringAppointment.value;
  awaitOverlayDebounce.value = false;
}

onBeforeUnmount(() => {
  removeListener();
});
</script>
