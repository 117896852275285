<template>
  <patient-link
    v-if="patient !== undefined"
    :format="format"
    :patient="patient"
  />
  <div
    class="m-0 inline-block h-3 w-24 transform animate-pulse rounded-lg bg-gray-200"
    v-else
  />
</template>

<script lang="ts" setup>
import { toRef } from "vue";
import { usePatient } from "@/composables/patient";
import { FormatName } from "@/models/Person";

const props = defineProps<{ zisNumber: number; format?: FormatName }>();

const { data: patient } = usePatient(toRef(props, "zisNumber"));
</script>
