<template>
  <div class="patient-edit-forms">
    <div class="space-y-2">
      <div
        v-for="(professional, index) in form.healthcare_professionals"
        :key="index"
      >
        <HealthcareProfessionalCrud
          @deleted="form.healthcare_professionals.splice(index, 1)"
          @updated="form.healthcare_professionals.splice(index, 1, $event)"
          :professional="professional"
          :patient-zis-number="patient.zis_number"
        />
      </div>
      <HealthcareProfessionalCrud
        v-if="showCreateForm"
        :professional="undefined"
        @created="
          ($event) => {
            form.healthcare_professionals.push($event);
            showCreateForm = false;
          }
        "
        @deleted="showCreateForm = false"
        :patient-zis-number="patient.zis_number"
      />
    </div>
    <div class="add-more">
      <template
        v-if="form.healthcare_professionals.length > 0 && !showCreateForm"
      >
        <flux-button @click="add" type="text" icon="fal fa-plus"
          >{{ $t("patient.edit.healthcare_professionals.add_more") }}
        </flux-button>
      </template>
      <template v-else-if="!showCreateForm">
        <flux-card shadow="never">
          <div style="text-align: center">
            <img
              src="../../assets/images/connected.svg"
              style="width: 100%; max-width: 300px"
            />
          </div>
          <div style="text-align: center; margin-top: 14px">
            {{ $t("patient.edit.healthcare_professionals.empty") }}
          </div>
          <div style="text-align: center; margin-top: 14px">
            <flux-button
              icon="fal fa-plus"
              ref="btn_add_more"
              type="primary"
              @click="add()"
              >{{
                $t("patient.edit.healthcare_professionals.add_first")
              }}</flux-button
            >
          </div>
        </flux-card>
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { apiClient } from "@/libraries/utils/axios";
import { onMounted, reactive, ref, watch } from "vue";
import { z } from "zod";
import { Patient } from "@/composables/patient";
import HealthcareProfessionalCrud from "@/components/HealthcareProfessional/HealthcareProfessionalCrud.vue";
import {
  HealthcareProfessional,
  healthcareProfessionalSchema,
} from "@/models/HealthcareProfessional";

const props = defineProps<{
  patient: Patient;
}>();

const form: {
  healthcare_professionals: Array<HealthcareProfessional>;
} = reactive({
  healthcare_professionals: [],
});

const specialisms = ref<any[]>([]);

onMounted(() => {
  apiClient.get("/specialisms").then(({ data }) => {
    specialisms.value = data.specialisms;
  });
});

const showCreateForm = ref(false);

watch(
  () => props.patient,
  () => {
    const hP = (props.patient as any).healthcare_professionals;
    form.healthcare_professionals = z
      .array(healthcareProfessionalSchema)
      .parse(hP);
  },
  { immediate: true, deep: true },
);

function add() {
  showCreateForm.value = true;
}
</script>
