import { apiClient } from "@/libraries/utils/axios";
import { z } from "zod";
import * as Sentry from "@sentry/vue";

export async function sbvzCheck(
  bsn?: string,
  patientZisNumber?: number,
): Promise<sbvzData | undefined> {
  const result = await apiClient.post("/apps/sbv-z/search", {
    patient_zis_number: patientZisNumber,
    query: bsn, // test bsn '123456782'
  });

  const parsedResult = sbvzScheme.safeParse(result.data);
  if (!parsedResult.success) {
    Sentry.captureException(parsedResult.error);
    return undefined;
  } else {
    return parsedResult.data;
  }
}

const sbvzScheme = z.union([
  z.object({
    success: z.literal(true),
    result: z.object({
      BSN: z.string(),
      Voornamen: z.string(),
      AdellijkeTitelPredikaat: z.string(),
      VoorvoegselGeslachtsnaam: z.string(),
      Geslachtsnaam: z.string(),
      Geboortedatum: z.string(),
      Geboorteplaats: z.string(),
      Geboorteland: z.string(),
      Geslachtsaanduiding: z.string(),
      GemeenteVanInschrijving: z.string(),
      FunctieAdres: z.string(),
      Gemeentedeel: z.string(),
      Straatnaam: z.string(),
      Huisnummer: z.string(),
      Huisletter: z.string(),
      Huisnummertoevoeging: z.string(),
      AanduidingBijHuisnummer: z.string(),
      Postcode: z.string(),
      Locatiebeschrijving: z.string(),
      LandVanwaarIngeschreven: z.string(),
      Woonplaatsnaam: z.string(),
      Regel1AdresBuitenland: z.string(),
      Regel2AdresBuitenland: z.string(),
      Regel3AdresBuitenland: z.string(),
      LandAdresBuitenland: z.string(),
      DatumAanvangAdresBuitenland: z.string(),
    }),
  }),
  z.object({ success: z.literal(false) }),
]);

export type sbvzData = z.infer<typeof sbvzScheme>;
