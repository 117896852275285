import { genderTypes } from "@/models/Patient";
import { z } from "zod";

export const patientSchema = z.object({
  zis_number: z.number(),
  first_names: z.string().optional(),
  initials: z.string().optional(),
  surname: z.string().optional(),
  surname_prefix: z.string().optional(),
  maiden_name: z.string().optional(),
  maiden_name_prefix: z.string().optional(),
  gender: z.enum(genderTypes).optional(),
  date_of_birth: z.optional(z.string().transform((x) => new Date(x))),
  nickname: z.string().optional(),
  photo_filename: z.string().optional(),
  company_division_id: z.string().optional(),
  employee_number: z.string().optional(),
  date_of_death: z.optional(
    z.string().transform((x) => (x ? new Date(x) : null)),
  ),
  deceased: z.boolean().optional(),
  inactive_at: z.optional(
    z.string().transform((x) => (x ? new Date(x) : null)),
  ),
});

export const searchResultSchema = z.object({
  patients: z.array(patientSchema),
});

export type PatientSearchResult = z.output<typeof patientSchema>;

export interface PatientRepository {
  search(query: string): Promise<PatientSearchResult[]>;
  activatePatient(zis_number: number): Promise<void>;
  deactivatePatient(zis_number: number): Promise<void>;
}
