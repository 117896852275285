export const colorStyles = {
  rose: {
    name: "rose",
    background: "bg-rose-100",
    hover: "hover:bg-rose-200/80",
    ring: "ring-rose-500",
    border: "border-rose-500",
    outline: "outline-rose-500",
    text: "text-rose-900",
    active_text: "text-rose-50 active:text-rose-50",
    active_background: "bg-rose-400",
  },
  red: {
    name: "red",
    background: "bg-red-100",
    hover: "hover:bg-red-200/80",
    ring: "ring-red-500",
    border: "border-green-500",
    outline: "outline-red-500",
    text: "text-red-900",
    active_text: "text-red-50 active:text-red-50",
    active_background: "bg-red-400",
  },
  orange: {
    // the good one
    name: "orange",
    background: "bg-orange-100",
    hover: "hover:bg-orange-200/80",
    ring: "ring-orange-600",
    border: "border-orange-600",
    outline: "outline-orange-600",
    text: "text-orange-900",
    active_text: "text-orange-50 active:text-orange-50",
    active_background: "bg-orange-400",
  },
  amber: {
    name: "amber",
    background: "bg-amber-100",
    hover: "hover:bg-amber-200/80",
    ring: "ring-amber-500",
    border: "border-amber-500",
    outline: "outline-amber-500",
    text: "text-amber-900",
    active_text: "text-amber-50 active:text-amber-50",
    active_background: "bg-amber-400",
  },
  yellow: {
    name: "yellow",
    background: "bg-yellow-100",
    hover: "hover:bg-yellow-200/80",
    ring: "ring-yellow-500",
    border: "border-yellow-500",
    outline: "outline-yellow-500",
    text: "text-yellow-900",
    active_text: "text-yellow-50 active:text-yellow-50",
    active_background: "bg-yellow-400",
  },
  lime: {
    name: "lime",
    background: "bg-lime-100",
    hover: "hover:bg-lime-200/80",
    ring: "ring-lime-500",
    border: "border-lime-500",
    outline: "outline-lime-500",
    text: "text-lime-900",
    active_text: "text-lime-50 active:text-lime-50",
    active_background: "bg-lime-400",
  },
  green: {
    name: "green",
    background: "bg-green-100",
    hover: "hover:bg-green-200/80",
    ring: "ring-green-500",
    border: "border-green-500",
    outline: "outline-green-500",
    text: "text-green-900",
    active_text: "text-green-50 active:text-green-50",
    active_background: "bg-green-400",
  },
  emerald: {
    name: "emerald",
    background: "bg-emerald-100",
    hover: "hover:bg-emerald-200/80",
    ring: "ring-emerald-500",
    border: "border-emerald-500",
    outline: "outline-emerald-500",
    text: "text-emerald-900",
    active_text: "text-emerald-50 active:text-emerald-50",
    active_background: "bg-emerald-400",
  },
  teal: {
    name: "teal",
    background: "bg-teal-100",
    hover: "hover:bg-teal-200/80",
    ring: "ring-teal-500",
    border: "border-teal-500",
    outline: "outline-teal-500",
    text: "text-teal-900",
    active_text: "text-teal-50 active:text-teal-50",
    active_background: "bg-teal-400",
  },
  cyan: {
    name: "cyan",
    background: "bg-cyan-100",
    hover: "hover:bg-cyan-200/80",
    ring: "ring-cyan-500",
    border: "border-cyan-500",
    outline: "outline-cyan-500",
    text: "text-cyan-900",
    active_text: "text-cyan-50 active:text-cyan-50",
    active_background: "bg-cyan-400",
  },
  sky: {
    name: "sky",
    background: "bg-sky-100",
    hover: "hover:bg-sky-200/80",
    ring: "ring-sky-500",
    border: "border-sky-500",
    outline: "outline-sky-500",
    text: "text-sky-900",
    active_text: "text-sky-50 active:text-sky-50",
    active_background: "bg-sky-400",
  },
  blue: {
    name: "blue",
    background: "bg-blue-100",
    hover: "hover:bg-blue-200/80",
    ring: "ring-blue-600",
    border: "border-blue-600",
    outline: "outline-blue-600",
    text: "text-blue-900",
    active_text: "text-blue-50 active:text-blue-50",
    active_background: "bg-blue-400",
  },
  indigo: {
    name: "indigo",
    background: "bg-indigo-100",
    hover: "hover:bg-indigo-200/80",
    ring: "ring-indigo-500",
    border: "border-indigo-500",
    outline: "outline-indigo-500",
    text: "text-indigo-900",
    active_text: "text-indigo-50 active:text-indigo-50",
    active_background: "bg-indigo-400",
  },
  violet: {
    name: "violet",
    background: "bg-violet-100",
    hover: "hover:bg-violet-200/80",
    ring: "ring-violet-500",
    border: "border-violet-500",
    outline: "outline-violet-500",
    text: "text-violet-900",
    active_text: "text-violet-50 active:text-violet-50",
    active_background: "bg-violet-400",
  },
  purple: {
    name: "purple",
    background: "bg-purple-100",
    hover: "hover:bg-purple-200/80",
    ring: "ring-purple-500",
    border: "border-purple-500",
    outline: "outline-purple-500",
    text: "text-purple-900",
    active_text: "text-purple-50 active:text-purple-50",
    active_background: "bg-purple-400",
  },
  fuchsia: {
    name: "fuchsia",
    background: "bg-fuchsia-100",
    hover: "hover:bg-fuchsia-200/80",
    ring: "ring-fuchsia-500",
    border: "border-fuchsia-500",
    outline: "outline-fuchsia-500",
    text: "text-fuchsia-900",
    active_text: "text-fuchsia-50 active:text-fuchsia-50",
    active_background: "bg-fuchsia-400",
  },
  pink: {
    name: "pink",
    background: "bg-pink-100",
    hover: "hover:bg-pink-200/80",
    ring: "ring-pink-500",
    border: "border-pink-500",
    outline: "outline-pink-500",
    text: "text-pink-900",
    active_text: "text-pink-50 active:text-pink-50",
    active_background: "bg-pink-400",
  },
  gray: {
    name: "gray",
    background: "bg-gray-100",
    hover: "hover:bg-gray-200/80",
    ring: "ring-gray-500",
    border: "border-gray-500",
    outline: "outline-groutline-500",
    text: "text-gray-900",
    active_text: "text-gray-50 active:text-gray-50",
    active_background: "bg-gray-400",
  },
} as const;

export type ColorStyleColor = keyof typeof colorStyles;
export type ColorStyle = (typeof colorStyles)[ColorStyleColor];
