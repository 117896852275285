<template>
  <div class="inline-flex items-stretch rounded-md shadow-sm">
    <flux-button
      class="rounded-r-none"
      :disabled="disabled"
      :icon="icon"
      :iconPosition="iconPosition"
      :size="size"
      :type="type"
      @click="emit('buttonClick')"
    >
      <slot name="button"></slot>
    </flux-button>
    <div class="relative -ml-px block">
      <flux-dropdown
        class="h-full"
        buttonClass="h-full rounded-l-none border-l-1 border-b-0 border-t-0 border-r-0 border-white hover:border-white pl-0 pr-2.5"
        :icon="
          dropDirection == 'down' ? 'fal fa-chevron-down' : 'fal fa-chevron-up'
        "
        :buttonSize="size"
        label=""
        :dropDirection="dropDirection"
        :typeButton="type"
      >
        <slot name="dropdown"></slot>
      </flux-dropdown>
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    nativeButtonType?: string;
    type?:
      | "primary"
      | "secondary"
      | "warning"
      | "error"
      | "delete"
      | "text"
      | "default";
    size?: "medium" | "small" | "mini";
    icon?: string;
    iconPosition?: "left" | "right";
    disabled?: boolean;
    dropDirection?: "up" | "down";
  }>(),
  {
    nativeButtonType: "button",
    type: "default",
    size: "medium",
    iconPosition: "left",
    disabled: false,
    dropDirection: "down",
  },
);

const emit = defineEmits<{
  buttonClick: [];
}>();
</script>
