import "nprogress/nprogress.css";
import "./assets/styles/main.css";

import "./polyfills";

import { createApp } from "vue";
import * as Sentry from "@sentry/vue";
import AppComponent from "./App.vue";
import router from "./libraries/router";
import plugins from "./libraries/plugins";
import store from "./libraries/store";
import i18n from "./libraries/i18n";
import { VueQueryPlugin } from "@tanstack/vue-query";

import { configureApiClient } from "./libraries/utils/axios";
import "./filters";

import ClickableIconComponent from "./components/ClickableIcon.vue";
import ClickableIconGroupComponent from "@/components/ClickableIconGroup.vue";
import PatientPhotoComponent from "@/components/Patient/Photo.vue";
import PatientInfoComponent from "@/components/Patient/Info.vue";
import ItemComponent from "@/components/questionnaire/Item.vue";
import { FluxUi } from "./libraries/plugins/ui";
import Loading from "@/components/ui/Loading.vue";

import moment from "moment";
import "moment/dist/locale/nl";
import { render, h } from "vue";
import { useNotify } from "@/composables/notify";
import hotkeyManager from "./libraries/utils/hotkeyManager";

const { notify } = useNotify();

moment.locale("nl");

configureApiClient(store, router);

const app = createApp({
  store,
  router,

  render: () => h(AppComponent),

  created() {
    document.addEventListener("keydown", (ev) => {
      if (ev.repeat) {
        return;
      }
      if (
        document.activeElement &&
        (["input", "textarea"].includes(
          document.activeElement.nodeName.toLowerCase(),
        ) ||
          (document.activeElement instanceof HTMLElement &&
            document.activeElement.isContentEditable))
      ) {
        return;
      }

      hotkeyManager.triggerHotkeyIfRegistered(ev);
    });
    if (window.sessionStorage.getItem("chunkLoadRefreshMessage") !== null) {
      notify({
        type: "success",
        message: this.$t("update.message") as string,
        showClose: true,
      });
      window.sessionStorage.removeItem("chunkLoadRefreshMessage");
    }
  },
});

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    ignoreErrors: [
      "ChunkLoadError",
      "Navigation aborted",
      "NavigationDuplicated",
      "structuredClone",
      "at is not a function",
      "randomUUID is not a function",
      "Failed to fetch dynamically imported module",
      "Navigation cancelled",
      "Network error",
      "Request failed with status code 401",
    ],
    release: import.meta.env.VITE_SENTRY_RELEASE || "",
    beforeSend(event, hint) {
      if (
        typeof hint.originalException !== "object" ||
        hint.originalException === null ||
        !("response" in hint.originalException)
      ) {
        return event;
      }
      const res = hint.originalException.response;
      if (
        typeof res !== "object" ||
        res === null ||
        !("status" in res) ||
        !("data" in res)
      ) {
        return event;
      }
      if (res.status === "422") {
        Sentry.addBreadcrumb({
          category: "debug",
          message: "Received 422",
          level: "info",
          data: {
            data: res.data,
          },
        });
      }
      return event;
    },
  });
}

// app.config.performance = true;

app.component("clickable-icon", ClickableIconComponent);
app.component("clickable-icon-group", ClickableIconGroupComponent);
app.component("patient-photo", PatientPhotoComponent);
app.component("patient-info", PatientInfoComponent);
app.component("Item", ItemComponent);
app.component("LoadingComponent", Loading);

app.use(VueQueryPlugin);
app.use(i18n);
app.use(router);
app.use(plugins(store));
app.use(FluxUi as any);

type LoadingElement = HTMLElement & { __loadingComponent: any };
function createLoadingComponent(el: LoadingElement) {
  el.style.position = "relative";
  el.appendChild(el.__loadingComponent.el);
}

function removeLoadingComponent(el: LoadingElement) {
  el.__loadingComponent.el?.remove();
  el.style.position = "";
}

const loadingComponent = app._context.components["LoadingComponent"];

app.directive("flux-loading", {
  mounted(el, binding) {
    const vnode = h(loadingComponent);
    el.__loadingComponent = vnode;
    render(el.__loadingComponent, el);

    if (binding.value) {
      createLoadingComponent(el);
    } else {
      removeLoadingComponent(el);
    }
  },
  updated(el, binding) {
    if (binding.value && el.__loadingComponent !== undefined) {
      createLoadingComponent(el);
    } else {
      removeLoadingComponent(el);
    }
  },
});

app.mount("#app");
