<template>
  <div class="mr-2 w-10">
    <div
      class="box-border flex h-10 w-10 items-center justify-around rounded-full border border-dashed border-gray-600 bg-gray-50 text-gray-600"
      v-if="getPatientParticipants(appointment).length === 0"
    >
      <i class="fal fa-question" />
    </div>
    <patient-photo
      v-else
      :zis_number="getPatientZisNumber(appointment)"
    ></patient-photo>
  </div>
  <div class="flex flex-col">
    <div>
      <template v-if="getPatientParticipants(appointment).length === 0">
        <span class="text-gray-800" v-if="appointment.metadata">
          {{ fullName(appointment.metadata.request) }}
        </span>
        <template v-else> Onbekend </template>
      </template>
      <span v-else>
        <patient-link
          v-if="appointmentPatient"
          :format="FormatName.fullName"
          :patient="appointmentPatient"
        />
        <async-patient-link
          v-else
          :format="FormatName.fullName"
          :zisNumber="getPatientZisNumber(appointment)"
        />
      </span>
    </div>
    <div class="text-sm text-gray-500">
      Afspraak met
      <span class="text-gray-800" v-if="!getUserFromAppointment(appointment)">
        Geen gebruiker
      </span>
      <span class="text-gray-800" v-else
        >{{ getUserFromAppointment(appointment) }}
      </span>
      op
      <span class="text-gray-800">{{
        compactDatetimeHumanFilter(appointment.start)
      }}</span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { compactDatetimeHumanFilter } from "@/filters";
import store from "@/libraries/store";
import {
  Appointment,
  AppointmentProposal,
  getPatientParticipants,
  getUserParticipants,
} from "@/models/Appointment";
import { Patient } from "@/models/Patient";
import { FormatName, fullName, fullNameInitials } from "@/models/Person";
import { computed } from "vue";

const props = defineProps<{
  appointment: AppointmentProposal;
}>();

const appointmentPatient = computed(() => getPatient(props.appointment));
const emit = defineEmits<{
  (e: "visit"): void;
}>();

function getPatient(appointment: Appointment): Patient | undefined {
  const zis_number = getPatientZisNumber(appointment);
  return store.state.calendar.patients?.[zis_number];
}

function getPatientZisNumber(appointment: Appointment) {
  return getPatientParticipants(appointment)[0]?.patient_zis_number;
}

function getUserFromAppointment(appointment: Appointment) {
  const id = getUserParticipants(appointment)[0]?.user_id;

  return fullNameInitials(
    store.state.user?.healthcare_provider.users.find((user) => user.id === id),
  );
}
</script>
