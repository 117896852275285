<template>
  <div>
    <div class="relative" ref="tabHeader">
      <div
        class="pointer-events-none absolute bottom-[4px] left-0 top-0 z-20 w-3 transform bg-gradient-to-r from-gray-300/50 to-gray-400/0 opacity-0 delay-75 duration-500"
        ref="shadowLeft"
      ></div>
      <div
        class="scrollbar-hidden relative z-10 flex min-w-full max-w-[0px] select-none flex-nowrap overflow-x-auto"
      >
        <div
          class="w-px flex-none translate-x-6 transform"
          ref="intersectionLeft"
        ></div>
        <div
          v-for="tab in manager.tabs"
          :id="`tab_${tab.name}`"
          :key="tab.name"
          @click="selectTab(tab)"
          style="scroll-margin: 4rem"
          :class="[
            'transform transition duration-75',
            'mr-8 box-border cursor-pointer whitespace-nowrap px-0 py-2 text-sm',
            manager.activeTabName === tab.name
              ? 'border-b-2 border-blue-500 text-blue-500'
              : ' border-b-2 border-transparent text-gray-500 hover:text-slate-800',
            tab.disabled
              ? '!cursor-default !text-gray-400 hover:text-gray-400'
              : '',
          ]"
        >
          <flux-badge v-if="tab.badgeColor" :color="tab.badgeColor">{{
            tab.label
          }}</flux-badge>
          <template v-else>{{ tab.label }}</template>
        </div>
        <div
          class="w-px flex-none -translate-x-6 transform"
          ref="intersectionRight"
        ></div>
      </div>
      <div
        class="pointer-events-none absolute bottom-[4px] right-0 top-0 z-20 w-3 transform bg-gradient-to-l from-gray-300/50 to-gray-400/0 opacity-0 delay-75 duration-500"
        ref="shadowRight"
      ></div>
      <div
        class="z-0 translate-y-[-1px] transform border-b border-gray-200"
      ></div>
    </div>
    <div :class="hasContent ? 'mt-6' : ''">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Tab, TabManager } from "@/libraries/managers/TabManager";
import { onMounted, provide, reactive, ref, watch } from "vue";

const activeTab = defineModel<string>("activeTab", { required: true });

const props = withDefaults(
  defineProps<{
    hasContent?: boolean;
  }>(),
  {
    hasContent: true,
  },
);

const emit = defineEmits<{
  (e: "update:activeTab", value: string): void;
}>();

const manager = reactive(new TabManager());
provide("tabManager", manager);

const shadowRight = ref<HTMLDivElement>();
const shadowLeft = ref<HTMLDivElement>();
const intersectionRight = ref<HTMLDivElement>();
const intersectionLeft = ref<HTMLDivElement>();
const tabHeader = ref<HTMLDivElement>();

onMounted(() => {
  addScrollShadows();
});

function addScrollShadows() {
  if (
    !shadowLeft.value ||
    !intersectionLeft.value ||
    !shadowRight.value ||
    !intersectionRight.value
  ) {
    return;
  }

  addScrollShadow(shadowLeft.value, intersectionLeft.value);
  addScrollShadow(shadowRight.value, intersectionRight.value);
}

function addScrollShadow(
  shadowElement: HTMLDivElement,
  intersectionElement: HTMLDivElement,
) {
  const handler = (entries: IntersectionObserverEntry[]) => {
    const showShadow = !entries.some((entry) => entry.isIntersecting);
    shadowElement.style.setProperty("opacity", showShadow ? "100%" : "0");
  };

  const observer = new IntersectionObserver(handler, {
    root: tabHeader.value,
    threshold: 0.1,
  });

  observer.observe(intersectionElement);
}

watch(
  activeTab,
  () => {
    if (!activeTab.value) {
      return;
    }
    manager.setActiveTabName(activeTab.value);
  },
  { immediate: true },
);

watch(
  () => manager.activeTabName,
  () => {
    if (!manager.activeTabName) {
      return;
    }
    scrollTabButtonIntoView();
    emit("update:activeTab", manager.activeTabName);
  },
);

function scrollTabButtonIntoView() {
  const element = document.getElementById(
    "tab_" + manager.activeTabName,
  ) as HTMLDivElement | null;

  element?.scrollIntoView({
    behavior: "smooth",
    inline: "nearest",
    block: "nearest",
  });
}

function selectTab(tab: Tab) {
  if (tab.disabled) {
    return;
  }
  manager.setActiveTabName(tab.name);
}
</script>

<style>
.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}
</style>
