<template>
  <div class="mx-auto flex w-full max-w-6xl flex-col space-y-8">
    <patient-info
      :zis_number="route.params.zis_number"
      :key="`${route.params.zis_number}`"
    >
      <template #actions>
        <router-link
          class="clickable-icon"
          :to="{ path: '/patient/' + $route.params.zis_number }"
        >
          <span class="fal fa-files-medical"></span>
        </router-link>
      </template>
    </patient-info>

    <flux-secondary-menu
      v-model:modelValue="state"
      v-if="patient"
      :collapse="true"
      :key="patient.zis_number"
    >
      <flux-secondary-menu-item
        :label="$t('patient.create.form.step.identity_documents.title_menu')"
        prop="identity_documents"
      >
        <PatientEditIdentityDocumentsComponent
          :zis-number="patient.zis_number"
        />
      </flux-secondary-menu-item>
      <flux-secondary-menu-item
        v-if="showCompany"
        :label="$t('patient.create.form.step.company.title_menu')"
        prop="company"
      >
        <PatientEditCompanyComponent :zis-number="patient.zis_number" />
      </flux-secondary-menu-item>
      <flux-secondary-menu-item
        :label="$t('patient.create.form.step.name.title_menu')"
        prop="personalia"
      >
        <PatientEditPersonaliaComponent :zisNumber="patient.zis_number" />
      </flux-secondary-menu-item>
      <flux-secondary-menu-item
        :label="$t('patient.create.form.step.contact_info.title_menu')"
        prop="contact_info"
      >
        <PatientEditContactInfoComponent :zis-number="patient.zis_number" />
      </flux-secondary-menu-item>
      <flux-secondary-menu-item
        :label="$t('patient.create.form.step.addresses.title_menu')"
        prop="addresses"
      >
        <PatientEditAddressesComponent :zis-number="patient.zis_number" />
      </flux-secondary-menu-item>
      <flux-secondary-menu-item
        :label="$t('patient.create.form.step.contacts.title_menu')"
        prop="contacts"
      >
        <PatientEditContactsComponent :zis-number="patient.zis_number" />
      </flux-secondary-menu-item>
      <flux-secondary-menu-item
        :label="$t('patient.create.form.step.payer_insurers.title_menu')"
        prop="payer_insurer"
      >
        <PatientEditPayerInsurerComponent
          :zis-number="patient.zis_number"
          :hasBSN="hasBSN"
        />
      </flux-secondary-menu-item>
      <flux-secondary-menu-item
        v-if="
          hasFeatureFlag('invoice-generation') &&
          hasFeatureFlag('insurer-contracts')
        "
        :label="$t('patient.edit.invoices.title_menu')"
        prop="invoices"
      >
        <PatientEditInvoiceSettingsComponent :zis-number="patient.zis_number" />
      </flux-secondary-menu-item>
      <flux-secondary-menu-item
        :label="
          $t('patient.create.form.step.healthcare_professionals.title_menu')
        "
        prop="healthcare_professionals"
      >
        <PatientEditHealthcareProfessionalsComponent :patient="patient" />
      </flux-secondary-menu-item>
      <flux-secondary-menu-item
        v-if="hasFeatureFlag('patient-labels')"
        :label="$t('patient.edit.labels.title_menu')"
        prop="labels"
      >
        <PatientEditLabels :patient="patient" />
      </flux-secondary-menu-item>
      <flux-secondary-menu-item
        v-if="hasFeatureFlag('nivel')"
        :label="$t('patient.edit.nivel.title_menu')"
        prop="nivel"
      >
        <PatientEditNivelConsent :patient="patient" />
      </flux-secondary-menu-item>
      <flux-secondary-menu-item
        v-if="hasFeatureFlag('ldf')"
        :label="$t('patient.edit.ldf.title_menu')"
        prop="ldf"
      >
        <PatientEditLdfConsent :patient="patient" />
      </flux-secondary-menu-item>
      <flux-secondary-menu-item
        :label="$t('patient.edit.archive.title_menu')"
        prop="archive"
      >
        <PatientEditArchive :patient="patient" />
      </flux-secondary-menu-item>
    </flux-secondary-menu>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch } from "vue";
import PatientEditPersonaliaComponent from "./PatientEditPersonalia.vue";
import PatientEditIdentityDocumentsComponent from "./PatientEditIdentityDocuments.vue";
import PatientEditContactInfoComponent from "./PatientEditContactInfo.vue";
import PatientEditAddressesComponent from "./PatientEditAddresses.vue";
import PatientEditContactsComponent from "./PatientEditContacts.vue";
import PatientEditHealthcareProfessionalsComponent from "./PatientEditHealthcareProfessionals.vue";
import PatientEditInvoiceSettingsComponent from "./PatientEditInvoiceSettings.vue";
import PatientEditPayerInsurerComponent from "./PatientEditPayerInsurer.vue";
import PatientEditCompanyComponent from "./PatientEditCompany.vue";
import { hasFeatureFlag } from "@/libraries/plugins/hasFeatureFlag";
import { usePatient } from "@/composables/patient";
import { useRoute } from "vue-router";
import { useCountryStore } from "@/libraries/store/Countries";
import { pinia } from "@/libraries/store";
import PatientEditLabels from "@/components/PatientEdit/PatientEditLabels.vue";
import PatientEditNivelConsent from "@/components/PatientEdit/PatientEditNivelConsent.vue";
import { usePatientRoute } from "@/composables/patientRoute";
import PatientEditLdfConsent from "./PatientEditLdfConsent.vue";
import PatientEditArchive from "@/components/PatientEdit/PatientEditArchive.vue";

export type PatientEditState =
  | "identity_documents"
  | "personalia"
  | "contact_info"
  | "addresses"
  | "contacts"
  | "payer_insurer";

const route = useRoute();
const { zisNumber } = usePatientRoute();
const { data: patient } = usePatient(zisNumber);

const state = ref<
  | "identity_documents"
  | "personalia"
  | "contact_info"
  | "addresses"
  | "contacts"
  | "payer_insurer"
>("identity_documents");

const hasBSN = ref(true);

watch(
  () => route.hash,
  function onRouteChange() {
    handleHash();
  },
);

onMounted(() => {
  useCountryStore(pinia).findAll();

  handleHash();
});

function handleHash() {
  const states: PatientEditState[] = [
    "identity_documents",
    "personalia",
    "contact_info",
    "addresses",
    "contacts",
    "payer_insurer",
  ];

  // Extract the hash without the leading '#'
  const hashState = route.hash.slice(1);

  if (states.includes(hashState as PatientEditState)) {
    state.value = hashState as PatientEditState;
  }
}

const showCompany = computed((): boolean => {
  return hasFeatureFlag("companies");
});
</script>
