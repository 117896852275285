import { hasFeatureFlag } from "@/libraries/plugins/hasFeatureFlag";
import { getCompanies } from "@/queries/companies/companies";
import { companiesKeys } from "@/queries/companies/companiesQueryKeys";
import { useQuery, useQueryClient } from "@tanstack/vue-query";

const gcTime = 1000 * 60 * 5;
const staleTime = 1000 * 60 * 60 * 24;

export function useCompanies() {
  const queryClient = useQueryClient();
  function invalidateCompanies() {
    queryClient.invalidateQueries({
      queryKey: companiesKeys.companies(),
    });
  }

  const query = useQuery({
    gcTime,
    staleTime,
    queryKey: companiesKeys.companies(),
    queryFn: ({ queryKey }) => getCompanies(),
    enabled: hasFeatureFlag("companies"),
    throwOnError: true,
  });

  return { ...query, invalidateCompanies };
}
