<template>
  <TypeAheadComponent
    v-model="searchString"
    ref="typeahead_desktop"
    icon="far fa-search"
    :data="shownPatients"
    :multiData="true"
    @submit="toMajorSearch"
    :placeholder="placeholder"
    :combinedLength="combinedLength"
    @enter="onEnter"
  >
    <template #results="{ data, activeIndex }">
      <ResultList
        :listData="data"
        :activeIndex="activeIndex"
        @select="selectPatient"
      >
        <template #top>
          <li
            class="mb-2 mt-2 px-3 text-xs font-medium uppercase text-slate-900 dark:text-white"
          >
            {{ showType === "recent" ? "Recente patiënten" : "Patiënten" }}
          </li>
        </template>
        <template #entry="{ entry, index }">
          <a
            :class="[
              'group box-border flex w-full cursor-pointer items-center justify-between  px-3 py-2 text-sm transition duration-75',
              activeIndex === index
                ? 'bg-slate-100 text-slate-700 dark:bg-neutral-700'
                : 'text-slate-700 hover:bg-slate-100 active:text-slate-700 dark:text-slate-300 hover:dark:bg-neutral-700',
            ]"
          >
            <span> {{ fullName(entry) }} </span>
            <div class="flex justify-end gap-4 text-xs text-slate-400">
              <span v-if="entry.inactive_at">Inactive</span>
              <span v-if="entry.date_of_birth">{{
                dateFilter(entry.date_of_birth)
              }}</span>
            </div>
          </a>
        </template>
      </ResultList>
      <ResultList
        :listData="invoiceData"
        :activeIndex="activeIndex"
        @select="selectInvoice"
      >
        <template #top>
          <li
            class="mb-2 mt-2 px-3 text-xs font-medium uppercase text-slate-900 dark:text-white"
          >
            Facturen
          </li>
        </template>
        <template #entry="{ entry, index }">
          <a
            :class="[
              'group box-border flex w-full cursor-pointer items-center justify-between px-3 py-2 text-sm transition duration-75',
              activeIndex === index + (shownPatients?.length ?? 0)
                ? 'bg-slate-100 text-slate-700'
                : 'text-slate-700 hover:bg-slate-100 active:text-slate-700 dark:text-slate-300 hover:dark:bg-neutral-700',
            ]"
          >
            <span>{{ entry.number }} </span>
            <div class="flex justify-end gap-4 text-xs text-slate-400">
              <span v-if="entry.date">
                {{ entry.date ? dateFilter(entry.date) : "" }}
              </span>
            </div>
          </a>
        </template>
        <template #bottom>
          <li
            class="-mb-1 flex items-baseline gap-2 border-t border-gray-200 bg-gray-50 px-3 py-2 text-xs text-gray-500 dark:border-neutral-700 dark:bg-neutral-800 dark:text-white"
          >
            <div class="flex-grow">
              Type
              <kbd
                class="inline-flex h-4 w-4 items-center justify-around rounded border bg-white text-center font-mono dark:text-neutral-900"
              >
                /
              </kbd>
              om te zoeken,
              <kbd
                class="inline-flex h-4 w-4 items-center justify-around rounded border bg-white text-center font-mono dark:text-neutral-900"
                ><i class="fal fa-sort-alt"
              /></kbd>
              navigeren en
              <kbd
                class="inline-flex h-4 w-4 items-center justify-around rounded border bg-white text-center font-mono dark:text-neutral-900"
                >⏎</kbd
              >
              selecteren.
            </div>
          </li>
        </template>
      </ResultList>
    </template>
  </TypeAheadComponent>
</template>

<script lang="ts" setup>
import { useDebouncedValue } from "@/composables/debouncedValue";
import { ComponentPublicInstance, computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import TypeAheadComponent from "@/components/ui/Input/TypeAhead.vue";
import { PatientSearchResult } from "@/libraries/repositories/patientRepository";
import { $t } from "@/libraries/i18n";
import { useRecentPatients } from "@/composables/recentPatients";
import { usePatientSearch } from "@/composables/patientSearch";
import ResultList from "./ui/ResultList.vue";
import { dateFilter } from "@/filters";
import { InvoiceListEntry } from "@/libraries/repositories/invoiceRepository";
import { clone } from "@/libraries/utils/clone";
import { fullName } from "@/models/Person";
import { useInvoiceSearch } from "@/composables/InvoiceSearch";

const router = useRouter();

const searchString = ref("");
const { recentPatients } = useRecentPatients();

const typeahead_desktop = ref<ComponentPublicInstance | null>(null);

const placeholder = $t("general.search");

const combinedLength = computed(() => {
  return (shownPatients.value?.length ?? 0) + invoiceData.value.length;
});

const showType = computed(() => {
  if (searchString.value.length === 0) {
    return "recent";
  }

  return "results";
});

const shownPatients = computed(() => {
  if (showType.value === "recent") {
    return recentPatients.value;
  }

  return data.value;
});

const debouncedSearchString = useDebouncedValue(searchString, 150);

const { data } = usePatientSearch(debouncedSearchString);

const { data: invoice, enabled } = useInvoiceSearch(debouncedSearchString);

const invoiceData = computed(() => {
  if (!enabled.value || !invoice.value) {
    return [];
  }
  return clone(invoice.value).splice(0, 10);
});

function toMajorSearch() {
  const query = { query: searchString.value };
  router
    .push({
      name: "PatientSearchResults",
      query: query,
    })
    .catch(() => {
      // NavigationDuplicated
    });
}

function selectPatient(patient: PatientSearchResult) {
  searchString.value = "";
  getTypeheadInput()?.blur();
  router.push(getPath(patient)).catch(() => {
    // NavigationDuplicated
  });
}

function onEnter(index: number) {
  const patientCount = shownPatients.value?.length ?? 0;
  if (shownPatients.value && index < patientCount) {
    selectPatient(shownPatients.value[index]);
  } else {
    selectInvoice(invoiceData.value[index - patientCount]);
  }
}

function selectInvoice(invoice: InvoiceListEntry) {
  searchString.value = "";
  getTypeheadInput()?.blur();
  if (invoice.patient) {
    router
      .push(`/patient/${invoice.patient.zis_number}/invoices/${invoice.id}`)
      .catch(() => {
        // NavigationDuplicated
      });
    return;
  }
  router.push("/invoices/" + invoice.id).catch(() => {
    // NavigationDuplicated
  });
}

onMounted(() => {
  document.addEventListener("keydown", (ev) => {
    if (ev.key != "/") {
      return;
    }

    if (document.activeElement instanceof Element) {
      if (
        ["input", "textarea"].includes(
          document.activeElement.nodeName.toLowerCase(),
        ) ||
        (document.activeElement instanceof HTMLElement &&
          document.activeElement.isContentEditable)
      ) {
        return;
      }
    }

    ev.preventDefault();
    getTypeheadInput()?.focus();
  });
});

function getTypeheadInput() {
  if (typeahead_desktop.value) {
    return typeahead_desktop.value.$el.querySelector("input");
  }
}

function getPath(patient: PatientSearchResult): string {
  return "/patient/" + patient.zis_number;
}
</script>
