<template>
  <div class="relative inline-block">
    <slot name="button"></slot>
    <transition
      @after-leave="emit('afterLeave')"
      enter-active-class="transition duration-75 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-out"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <div
        class="absolute z-50 mt-2 max-h-96 w-max overflow-y-auto overflow-x-hidden whitespace-nowrap rounded-md border border-gray-300 bg-white py-1 shadow-md dark:border-neutral-700 dark:bg-neutral-800 dark:text-white"
        v-show="visible"
        :class="[getXPositionClass(), getYPositionClass(), getOriginClass()]"
      >
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script lang="ts" setup>
import { PropType } from "vue";

const props = defineProps({
  visible: {
    default: false,
    type: Boolean,
  },
  direction: {
    default: "left",
    type: String as PropType<"left" | "right">,
  },
  dropDirection: {
    default: "down",
    type: String as PropType<"up" | "down">,
  },
});

const emit = defineEmits<{ (e: "afterLeave"): void }>();

function getXPositionClass() {
  return props.direction === "left" ? "right-0" : "left-0";
}

function getYPositionClass() {
  return props.dropDirection === "up" ? "bottom-[calc(100%+0.5rem)]" : "";
}

function getOriginClass() {
  if (props.dropDirection === "down") {
    return props.direction === "left" ? "origin-top-right" : "origin-top-left";
  } else {
    return props.direction === "left"
      ? "origin-bottom-right"
      : "origin-bottom-left";
  }
}
</script>
