<template>
  <div>
    <div class="self-center" :id="uuid + '_label'">
      {{ item.text }}
    </div>
    <div>
      <div
        class="inline-block rounded border border-gray-400 bg-white px-4 py-2 text-sm"
        v-if="readonly"
      >
        {{ response[item.linkId] }}
      </div>
      <input
        class="appearance-none rounded border border-gray-400 bg-white px-4 py-2 text-sm outline-none transition duration-75 hover:border-gray-500 focus:border-blue-600 focus:ring invalid:border-red-600 invalid:ring-red-300"
        v-else
        v-model.number="response[item.linkId]"
        :id="'b_' + uuid"
        :pattern="item.type == 'integer' ? '\\d+' : '\\d+([\\.,]\\d+)?'"
        type="number"
        :step="item.type == 'integer' ? undefined : '0.01'"
        :min="minValue != -Infinity ? minValue : ''"
        :max="maxValue != -Infinity ? maxValue : ''"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  DecimalItem,
  EXTENSION,
  IntegerItem,
} from "@/libraries/questionnaires/item";
import { computed } from "vue";

/**
 * For behaviour of the radio group, see
 * https://www.w3.org/TR/wai-aria-practices/examples/radio/radio-1/radio-1.html
 */
const props = defineProps<{
  item: IntegerItem | DecimalItem;
  response: any;
  readonly: boolean;
  leftLabel?: string;
  rightLabel?: string;
}>();

const uuid = crypto.randomUUID();

const bounds = computed((): [number, number] => {
  if (!Array.isArray(props.item.extension)) {
    return [-Infinity, Infinity];
  }
  const minValue =
    props.item.extension?.find((ex) => ex.url === EXTENSION.MIN_VALUE)
      ?.valueDecimal ?? -Infinity;

  const maxValue =
    props.item.extension?.find((ex) => ex.url === EXTENSION.MAX_VALUE)
      ?.valueDecimal ?? Infinity;

  return [minValue, maxValue];
});

const minValue = computed(() => bounds.value[0]);
const maxValue = computed(() => bounds.value[1]);
</script>
