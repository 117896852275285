<template>
  <button
    type="button"
    @click="emit('click', $event)"
    :class="[
      'group z-0 flex w-full items-center gap-x-1 rounded bg-transparent p-0 text-left font-sans text-base outline-none ring-slate-400 ring-opacity-50 transition duration-75 focus:ring',
      'hover:cursor-pointer hover:bg-slate-100',
      'focus:bg-slate-100',
    ]"
  >
    <slot></slot>
  </button>
</template>

<script lang="ts" setup>
const emit = defineEmits(["click"]);
</script>
