<template>
  <div class="contents">
    <div>
      <div class="text-base font-semibold">{{ props.title }}</div>
      <span class="text-xs text-gray-600" v-if="!$slots.description">
        {{ props.description }}
      </span>
      <slot v-else name="description"></slot>
    </div>
    <slot></slot>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps<{
  title: string;
  description?: string;
}>();
</script>
