<template>
  <div class="flex flex-col divide-y divide-gray-200">
    <div
      class="group flex cursor-pointer p-2 hover:bg-slate-100"
      v-for="appointment in appointments"
      :key="appointment.id"
      @click="goToAppointment(appointment)"
    >
      <ProposedAppointmentsDropdownOption :appointment="appointment" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import store from "@/libraries/store";
import { Appointment, getUserParticipants } from "@/models/Appointment";
import { computed } from "vue";
import { useRouter } from "vue-router";
import { useCalendarNavigation } from "@/composables/calendarNavigation";
import { useAgendaUserSelection } from "@/composables/agendaUserSelection";
import ProposedAppointmentsDropdownOption from "./ProposedAppointmentsDropdownOption.vue";

const emit = defineEmits<{
  (e: "visit"): void;
}>();

const router = useRouter();
const { setDate } = useCalendarNavigation();
const { selectedUserIds, setSelectedUserIds } = useAgendaUserSelection();

const appointments = computed(() => {
  return store.state.calendar.appointmentProposals;
});

async function goToAppointment(appointment: Appointment) {
  emit("visit");
  if (!router.currentRoute.value.path.includes("calendar")) {
    await router.push("/calendar");
  }

  setDate(appointment.start.toDate());
  const selectedUser = selectUser(appointment);
  const reloadAppointment = store.dispatch(
    "calendar/reloadAppointment",
    appointment.id,
  );

  await Promise.all([selectedUser, reloadAppointment]);
  store.dispatch("calendar/activateAppointment", appointment.id);
}

async function selectUser(appointment: Appointment) {
  const userId = getUserParticipants(appointment)[0]?.user_id;
  const oldUserIds = Array.from(selectedUserIds.value);
  const userIds = new Set(oldUserIds);

  userIds.add(userId);
  setSelectedUserIds(Array.from(userIds));
}
</script>
