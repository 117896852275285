<template>
  <flux-input
    @update:model-value="setValue"
    :model-value="value?.toString()"
    type="date"
    :min="min?.toString()"
    :max="max?.toString()"
  />
</template>

<script lang="ts" setup>
defineProps<{
  min?: Temporal.PlainDate;
  max?: Temporal.PlainDate;
}>();

const value = defineModel<Temporal.PlainDate>();

function setValue(newValue: string) {
  value.value = Temporal.PlainDate.from(newValue);
}
</script>
