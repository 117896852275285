<template>
  <flux-form :errors="errors">
    <flux-card
      class="hides-icons mb-3"
      v-for="(address, index) in form.addresses"
      shadow="never"
    >
      <AddressComponent
        v-if="patient"
        v-model="form.addresses[index]"
        :patient="patient"
        @created="invalidatePatient(zisNumber)"
        @updated="invalidatePatient(zisNumber)"
        @errors="(e) => setErrors(e)"
        @removed="
          invalidatePatient(zisNumber);
          form.addresses.splice(index, 1);
        "
      ></AddressComponent>
    </flux-card>
    <div class="add-more">
      <template v-if="form.addresses.length > 0">
        <div class="mt-4 text-center">
          <flux-button-group
            class="inline-flex items-center justify-center pl-2"
          >
            <p v-if="sbvzLogData && sbvzLogData.length > 0">
              Laatste SBV-Z check:
              {{ datetimeFilter(sbvzLogData[0].at) }}
            </p>
            <flux-button
              v-if="sbvzEnabled"
              v-flux-loading="loading"
              icon="fal fa-redo"
              type="text"
              @click="perfomSbvzCheck()"
            >
              {{ $t("patient.sbvz-check.execute") }}
            </flux-button>
            <flux-button
              icon="fal fa-plus"
              ref="btn_add_more"
              type="primary"
              @click="addAddress()"
              >{{
                $t("patient.create.form.step.addresses.add_more")
              }}</flux-button
            >
          </flux-button-group>
        </div>
      </template>
      <template v-else>
        <flux-card shadow="never">
          <div style="text-align: center">
            <img
              src="../../assets/images/ordinary_day.svg"
              style="width: 100%; max-width: 300px"
            />
          </div>
          <div style="text-align: center; margin-top: 14px">
            {{ $t("patient.edit.addresses.empty") }}
          </div>
          <div
            class="flex justify-center gap-2"
            style="text-align: center; margin-top: 14px"
          >
            <flux-button
              v-if="sbvzEnabled"
              v-flux-loading="loading"
              icon="fal fa-redo"
              type="text"
              @click="perfomSbvzCheck()"
            >
              {{ $t("patient.sbvz-check.execute") }}
            </flux-button>
            <flux-button
              icon="fal fa-plus"
              ref="btn_add_more"
              type="primary"
              @click="addAddress()"
              >{{
                $t("patient.create.form.step.addresses.add_first")
              }}</flux-button
            >
          </div>
        </flux-card>
      </template>
    </div>
  </flux-form>
</template>

<script lang="ts" setup>
import { apiClient } from "@/libraries/utils/axios";
import { onMounted, reactive, ref, toRef, watch, computed } from "vue";
import {
  AddressType,
  addressTypeOptions as rawAddressTypeOptions,
} from "../../models/Address";
import { Country } from "../../models/Country";
import AddressComponent from "../Patient/Address.vue";
import { MessageBag } from "@/libraries/utils/MessageBag";
import { usePatient } from "@/composables/patient";
import { $t } from "@/libraries/i18n";
import { getUser } from "@/libraries/plugins/getUser";
import { hasFeatureFlag } from "@/libraries/plugins/hasFeatureFlag";
import { hasPermission } from "@/libraries/utils/hasPermission";
import { useNotify } from "@/composables/notify";
import { useSbvzLog } from "@/composables/sbvzLog";
import { sbvzCheck } from "@/queries/sbvzCheck";
import { datetimeFilter } from "@/filters";
import { AddressCreateForm } from "@/models/Forms";

const props = defineProps<{
  zisNumber: number;
}>();

const loading = ref(false);

const sbvzEnabled = computed(() => {
  return (
    getUser().healthcare_provider.sbvz_settings.enabled &&
    getUser().healthcare_provider.sbvz_settings.has_upload &&
    hasPermission("call-sbv-z", getUser()) &&
    hasFeatureFlag("sbv-z")
  );
});
const { data: patient, invalidatePatient } = usePatient(
  toRef(props, "zisNumber"),
);

const countries = ref<Country[]>([]);

const errors = ref(new MessageBag({}));

const form: { addresses: AddressCreateForm[] } = reactive({
  addresses: [],
});

const addressTypeOptions = ref<Array<{ value: string; label: string }>>();

watch(
  patient,
  function onPatientChanged(val) {
    if (val) {
      form.addresses = val.addresses.map((a) => {
        return {
          ...a,
          street_name: a.street_name ?? "",
          house_number: a.house_number ?? "",
          postal_code: a.postal_code ?? "",
          city: a.city ?? "",
          country_id: a.country_id,
        };
      });
    }
  },
  { immediate: true, deep: false },
);

onMounted(() => {
  const toOptions = (translation: string, list: Readonly<string[]>) =>
    list.map((value) => {
      return {
        value,
        label: $t(translation + "." + value),
      };
    });

  addressTypeOptions.value = toOptions(
    "labels.address_type",
    rawAddressTypeOptions,
  );

  apiClient.get("/countries").then((res) => {
    countries.value = res.data.countries;
  });
});

const netherlands = computed(() =>
  countries.value.find((c) => c.code === "NL"),
);

function addAddress(
  address: AddressCreateForm = {
    address_type: "PHYS",
    street_name: "",
    house_number: "",
    postal_code: "",
    city: "",
    country: netherlands.value,
    country_id: netherlands.value ? netherlands.value.id : 1,
  },
) {
  form.addresses.push(address);
}

function setErrors(e: any) {
  errors.value = new MessageBag(e);
}

const { notify } = useNotify();

const { data: sbvzLogData, invalidateSbvzLog } = useSbvzLog(
  toRef(props, "zisNumber"),
);

async function perfomSbvzCheck() {
  loading.value = true;
  try {
    const res = await sbvzCheck(undefined, props.zisNumber);

    if (res?.success) {
      notify({
        message: "SBV-Z check gelukt. Patiënt data wordt ingevuld.",
        type: "success",
      });

      let houseNumber = res.result.Huisnummer.toString();

      houseNumber = [
        houseNumber,
        res.result.Huisletter,
        res.result.Huisnummertoevoeging,
      ]
        .filter((x) => x)
        .join(" ");
      const address = {
        postal_code: res.result.Postcode,
        city: res.result.Woonplaatsnaam,
        house_number: houseNumber,
        street_name: res.result.Straatnaam,
        address_type: "HP" as AddressType,
        country_id: 159,
      };
      addAddress(address);
      invalidateSbvzLog(props.zisNumber);
    } else {
      notify({
        message: "SBV-Z check mislukt",
        type: "error",
      });
    }
  } catch {
    notify({
      message: "SBV-Z check mislukt",
      type: "error",
    });
  } finally {
    loading.value = false;
  }
}
</script>
