<template>
  <div
    class="group relative flex flex-col gap-2 rounded border border-gray-100 bg-white p-4"
    v-flux-loading="loading"
  >
    <clickable-icon-group
      class="absolute right-4 opacity-0 duration-75 group-hover:opacity-100"
    >
      <clickable-icon
        v-if="state === 'view'"
        icon="fa-pencil"
        @click="showEditForm"
      ></clickable-icon>
      <clickable-icon
        v-if="state === 'view'"
        icon="fa-trash-alt"
        @click="performDelete"
      ></clickable-icon>
    </clickable-icon-group>

    <HealthcareProfessionalView
      v-if="professional && state === 'view'"
      :professional="professional"
    />
    <HealthcareProfessionalForm
      v-if="state === 'edit' && editForm !== undefined"
      :form="editForm"
      @save="performEdit"
      @cancel="cancelEdit"
    >
      <template #actions>
        <flux-button-group class="mt-4 justify-end">
          <flux-button @click="cancelEdit">{{
            $t("general.cancel")
          }}</flux-button>
          <flux-button type="primary" @click="performEdit">{{
            $t("general.update")
          }}</flux-button>
        </flux-button-group>
      </template>
    </HealthcareProfessionalForm>
    <HealthcareProfessionalForm
      v-if="state === 'create' && createForm !== undefined"
      :form="createForm"
      @save="performCreate"
      @cancel="cancelCreate"
    >
      <template #actions>
        <flux-button-group class="mt-4 justify-end">
          <flux-button @click="cancelCreate">{{
            $t("general.cancel")
          }}</flux-button>
          <flux-button type="primary" @click="performCreate">{{
            $t("general.create")
          }}</flux-button>
        </flux-button-group>
      </template>
    </HealthcareProfessionalForm>
  </div>
</template>
<script lang="ts" setup>
import { pinia } from "@/libraries/store";
import { Specialism, useSpecialismStore } from "@/libraries/store/Specialisms";
import {
  HealthcareProfessional,
  healthcareProfessionalSchema,
} from "@/models/HealthcareProfessional";
import { onMounted, ref } from "vue";
import HealthcareProfessionalView from "@/components/HealthcareProfessional/HealthcareProfessionalView.vue";
import HealthcareProfessionalForm from "@/components/HealthcareProfessional/HealthcareProfessionalForm.vue";
import { useNotify } from "@/composables/notify";
import { apiClient } from "@/libraries/utils/axios";
import { z } from "zod";
import { $t } from "@/libraries/i18n";
import { clone } from "@/libraries/utils/clone";
import { useConfirm } from "@/composables/confirm";

const { confirm } = useConfirm();

const { notify } = useNotify();

const props = defineProps<{
  professional?: HealthcareProfessional;
  patientZisNumber: number;
}>();

const loading = ref(false);

const state = ref<"view" | "edit" | "create">("view");

const specialisms = ref<Specialism[]>([]);
const specialismStore = useSpecialismStore(pinia);

const editForm = ref<HealthcareProfessional | undefined>();
const createForm = ref<Omit<HealthcareProfessional, "id">>();

const emit = defineEmits<{
  (e: "deleted"): void;
  (e: "updated", professional: HealthcareProfessional): void;
  (e: "created", professional: HealthcareProfessional): void;
}>();

function showEditForm() {
  state.value = "edit";
  editForm.value = clone(props.professional);
}

function cancelCreate() {
  emit("deleted");
}

async function performDelete() {
  if (
    !(await confirm({
      title: "Verwijder zorgverlener",
      message: $t("patient.edit.healthcare_professionals.confirm_delete"),
      type: "delete",
      confirmButtonText: $t("general.delete"),
      cancelButtonText: $t("general.cancel"),
    }))
  ) {
    return;
  }

  loading.value = true;
  try {
    await apiClient.delete(
      `/patients/${props.patientZisNumber}/healthcare_professionals/${props.professional?.id}`,
    );
    emit("deleted");
    notify({
      message: $t("patient.edit.healthcare_professionals.deleted"),
      type: "success",
    });
  } finally {
    loading.value = false;
  }
}

async function performCreate() {
  try {
    loading.value = true;
    const res = await apiClient.post(
      "/patients/:zis_number/healthcare_professionals",
      createForm.value,
      {
        params: {
          zis_number: props.patientZisNumber,
        },
      },
    );
    emit("created", healthcareProfessionalSchema.parse(res.data));
    state.value = "view";
    createForm.value = undefined;
    notify({
      message: $t("patient.edit.healthcare_professionals.created"),
      type: "success",
    });
  } finally {
    loading.value = false;
  }
}

async function performEdit() {
  if (props.professional === undefined) {
    throw new Error("No professional to edit");
  }

  loading.value = true;

  try {
    const id = props.professional.id;
    const res = await apiClient.put(
      "/patients/:zis_number/healthcare_professionals/:id",
      editForm.value,
      {
        params: {
          zis_number: props.patientZisNumber,
          id,
        },
      },
    );
    emit(
      "updated",
      z
        .object({ healthcare_professional: healthcareProfessionalSchema })
        .parse(res.data).healthcare_professional,
    );

    state.value = "view";
    editForm.value = undefined;
    notify({
      message: $t("patient.edit.healthcare_professionals.updated"),
      type: "success",
    });
  } finally {
    loading.value = false;
  }
}
function cancelEdit() {
  state.value = "view";
  editForm.value = undefined;
}

onMounted(function () {
  fetchSpecialisms();
  setState();
});

function setState() {
  if (props.professional !== undefined) {
    state.value = "view";
    return;
  }

  createForm.value = {
    fullname: "",
    role: "REF",
    specialism_code: "",
    AGB_code: "",
    UZI_code: "",
  };
  state.value = "create";
}

function fetchSpecialisms() {
  specialismStore.findAll().then((res) => {
    specialisms.value = res;
  });
}
</script>
