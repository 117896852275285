<template>
  <flux-modal
    :visible="visible"
    :onCancel="onCancel"
    :icon="icon"
    :closeable="false"
  >
    <slot>
      <p class="whitespace-pre-line text-sm text-gray-500">{{ body }}</p>
    </slot>
    <template #header>
      {{ header }}
    </template>
    <template #footer>
      {{ footer }}
      <flux-button
        :disabled="disabled ?? false"
        :type="button.type"
        :size="isMobileScreen ? 'medium' : 'small'"
        @click="() => onConfirm?.()"
      >
        {{ confirmButtonText || button.text }}
      </flux-button>
      <flux-button
        v-if="canCancel"
        :disabled="disabled ?? false"
        :size="isMobileScreen ? 'medium' : 'small'"
        @click="() => onCancel?.()"
      >
        {{ cancelButtonText || $t("general.cancel") }}
      </flux-button>
    </template>
  </flux-modal>
</template>

<script setup lang="ts">
import { $t } from "@/libraries/i18n";
import { ConfirmType } from "@/composables/confirm";
import { computed } from "vue";

interface Icon {
  class: string;
  color: string;
}

const props = withDefaults(
  defineProps<{
    type?: ConfirmType;
    customHeader?: string;
    customBody?: string;
    customFooter?: string;
    customIcon?: Icon;
    onConfirm?: () => void;
    onCancel?: () => void;
    visible?: boolean;
    confirmButtonText?: string;
    cancelButtonText?: string;
    canCancel?: boolean;
    disabled?: boolean;
  }>(),
  {
    type: "default",
    visible: false,
    canCancel: true,
  },
);

const header = computed(() => {
  if (props.customHeader) {
    return props.customHeader;
  }

  switch (props.type) {
    default:
      return $t("general.confirm");
  }
});

const body = computed(() => {
  if (props.customBody) {
    return props.customBody;
  }

  switch (props.type) {
    default:
      return "";
  }
});

const footer = computed(() => {
  if (props.customFooter) {
    return props.customFooter;
  }

  switch (props.type) {
    default:
      return "";
  }
});

const icon = computed(() => {
  if (props.customIcon) {
    return props.customIcon;
  }

  switch (props.type) {
    case "delete":
    case "danger":
      return {
        class: "fas fa-exclamation-circle",
        color: "text-red-500",
      };
  }

  return undefined;
});

const button = computed(() => {
  switch (props.type) {
    case "danger":
    case "delete":
      return {
        type: "delete" as const,
        text: $t("general.delete"),
      };
    default:
      return {
        type: "primary" as const,
        text: $t("general.confirm"),
      };
  }
});

const isMobileScreen = computed(() => {
  return window.innerWidth < 640;
});
</script>
