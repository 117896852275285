import { handleErrors } from "@/libraries/utils/errorHandling";
import {
  InvoiceApiBody,
  StoreCompanyProductInvoiceApiBody,
  StorePatientProductInvoiceApiBody,
} from "@/apis/patients/invoices";
import { Invoice, InvoiceApi, InvoiceStatus } from "@/models/Invoice";
import { AxiosInstance } from "axios";
import { format } from "date-fns";
import { z } from "zod";
import { $t } from "../i18n";
import { isValidationErrorResponse } from "../utils/errorHandling";
import { downloadInvoice } from "../utils/invoices/downloadInvoice";
import { MessageBag } from "../utils/MessageBag";
import { useNotify } from "@/composables/notify";

import {
  InvoiceRepository,
  InvoiceListEntry,
  PaginationData,
  responseSchema,
  invoiceDetailSchema,
  InvoiceSummary,
  invoiceSummarySchema,
} from "./invoiceRepository";

const { notify } = useNotify();
export class InvoiceRepositoryUsingApi implements InvoiceRepository {
  #api: AxiosInstance;

  constructor(apiClient: AxiosInstance) {
    this.#api = apiClient;
  }
  async findById(invoiceId: number) {
    return await this.#api
      .get(`/invoices/${invoiceId}`)
      .then((res) => invoiceDetailSchema.parse(res.data.invoice))
      .catch((error) => handleErrors({ error }));
  }
  async findForPatient(
    patientZisNumber: number,
    invoiceId: number,
  ): Promise<InvoiceApi> {
    return await this.#api
      .get(`/patients/${patientZisNumber}/invoices/${invoiceId}`)
      .then((res) => res.data);
  }

  async updateStatus(
    invoiceId: number,
    newStatus: InvoiceStatus,
  ): Promise<InvoiceApi> {
    return await this.#api
      .patch(`/invoices/${invoiceId}`, { status: newStatus })
      .then((res) => res.data.invoice);
  }

  async update(
    invoiceId: number,
    invoice:
      | InvoiceApiBody
      | StoreCompanyProductInvoiceApiBody
      | StorePatientProductInvoiceApiBody,
  ): Promise<InvoiceSummary | MessageBag | Error> {
    const responseSchema = z.object({
      data: z.object({ invoice: invoiceSummarySchema }),
    });

    try {
      const res = await this.#api.put(`/invoices/${invoiceId}`, invoice);
      return responseSchema.parse(res).data.invoice;
    } catch (err) {
      if (isValidationErrorResponse(err)) {
        return MessageBag.fromResponse(err.response);
      }

      return new Error(err);
    }
  }

  async updateForPatient(
    invoiceId: number,
    patientZisNumber: number,
    invoice: InvoiceApiBody,
  ): Promise<InvoiceSummary> {
    const responseSchema = z.object({
      data: z.object({ invoice: invoiceSummarySchema }),
    });
    return await this.#api
      .put(`/patients/${patientZisNumber}/invoices/${invoiceId}`, invoice)
      .then((res: unknown) => responseSchema.parse(res).data.invoice);
  }

  async createProductInvoice(
    body: StoreCompanyProductInvoiceApiBody | StorePatientProductInvoiceApiBody,
  ): Promise<InvoiceSummary | MessageBag | Error> {
    try {
      const res = await this.#api.post("/invoices/", body);
      return invoiceSummarySchema.parse(res.data.invoice);
    } catch (err) {
      if (isValidationErrorResponse(err)) {
        return MessageBag.fromResponse(err.response);
      }

      return new Error(err);
    }
  }
  async createForPatient(
    patientZisNumber: number,
    body: InvoiceApiBody,
    partialInvoiceReferenceDate?: Date,
  ): Promise<{ id: number } | MessageBag | Error> {
    let referenceDateQuery = "";
    if (partialInvoiceReferenceDate) {
      referenceDateQuery =
        "?reference_date=" + format(partialInvoiceReferenceDate, "y-MM-dd");
    }

    try {
      const res = await this.#api.post(
        `/patients/${patientZisNumber}/invoices${referenceDateQuery}`,
        body,
      );
      return invoiceSummarySchema.parse(res.data.invoice);
    } catch (err) {
      if (isValidationErrorResponse(err)) {
        return MessageBag.fromResponse(err.response);
      }

      return new Error(err);
    }
  }

  async findAllOnPage(
    url: string,
    params: any,
  ): Promise<{ data: InvoiceListEntry[] } & PaginationData> {
    const res = await this.#api.get(url, {
      params,
    });
    const parsed = responseSchema.parse(res.data);
    return parsed;
  }

  async download(invoice: Invoice): Promise<void> {
    return downloadInvoice(invoice);
  }

  async send(
    invoice: Invoice | InvoiceListEntry,
    doNotSendLatestCorrection?: boolean,
  ): Promise<void> {
    if (this.isPatientInvoice(invoice)) {
      await this.handleInvoiceSend(
        `/patients/${invoice.patient_zis_number}/invoices/${invoice.id}/send`,
      );
      return;
    }
    if (this.isCompanyInvoice(invoice)) {
      await this.handleInvoiceSend(`/invoices/${invoice.id}/send`);
      return;
    }
    notify({
      title: "De factuur kan niet worden verstuurd",
      message: "Ontvanger ontbreekt op de factuur",
      type: "error",
    });
  }

  async validate(invoice: Invoice): Promise<void> {
    if (!this.isCollectionInvoice(invoice)) {
      return;
    }
    await this.#api.post(`/invoices/${invoice.id}/vecozo/validate`);
  }

  async declare(invoice: Invoice): Promise<void> {
    if (!this.isCollectionInvoice(invoice)) {
      return;
    }
    await this.#api.post(`/invoices/${invoice.id}/vecozo/declare`);
  }

  async updatePeriod(
    invoice: Invoice,
    period_start: string,
    period_end: string,
  ): Promise<void> {
    if (!this.isCollectionInvoice(invoice)) {
      return;
    }
    await this.#api.patch(`/invoices/${invoice.id}`, {
      period_start,
      period_end,
    });
  }

  private async handleInvoiceSend(url: string) {
    try {
      await this.#api.post(url);
      notify({
        message: $t("finance.invoice.invoice_sent.success"),
        type: "success",
      });
    } catch (err) {
      const { status, data } = err.response;
      if (isValidationErrorResponse(err)) {
        const message = data.message.replace(
          "Cannot send invoice if recipient has no email",
          "Er is geen email adres bekend.",
        );
        notify({
          title: "De factuur kan niet worden verstuurd",
          message,
          type: "error",
        });
      } else {
        notify({
          title: "De factuur kan niet worden verstuurd",
          message: $t("error.unknown"),
          type: "error",
        });
      }
    }
  }

  private isPatientInvoice = (invoice: Invoice | InvoiceListEntry) => {
    return invoice.patient_zis_number !== undefined;
  };

  private isCompanyInvoice = (invoice: Invoice | InvoiceListEntry) => {
    return invoice.company_id !== undefined;
  };

  private isCollectionInvoice = (invoice: Invoice | InvoiceListEntry) => {
    return (
      invoice.status === "collection" ||
      invoice.status === "collection_draft" ||
      invoice.status === "collection_void"
    );
  };
}
