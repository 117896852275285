<template>
  <i :class="['fal', icon]"></i>
</template>
<script lang="ts" setup>
import { computed } from "vue";
import { getFileExtensionFromName } from "@/libraries/utils/files";

const icons = new Map(
  Object.entries({
    pdf: "fa-file-pdf text-red-600",

    zip: "fa-file-archive text-gray-600",

    jpg: "fa-file-image text-pink-600",
    png: "fa-file-image text-pink-600",
    jpeg: "fa-file-image text-pink-600",
    heic: "fa-file-image text-pink-600",
    bmp: "fa-file-image text-pink-600",

    xls: "fa-file-excel text-green-600",
    xlsx: "fa-file-excel text-green-600",

    doc: "fa-file-word text-blue-700",
    docx: "fa-file-word text-blue-700",
    txt: "fa-file-word text-blue-700",

    mp3: "fa-file-audio text-purple-600",
    mp4: "fa-file-video text-purple-600",
    mpeg: "fa-file-video text-purple-600",
    mov: "fa-file-video text-purple-600",
  }),
);

function getIcon(filetype: string): string {
  return icons.get(filetype) || "fa-file";
}

const props = defineProps<{
  filetype: string;
}>();

const icon = computed((): string => {
  if (props.filetype.includes(".")) {
    return getIcon(getFileExtensionFromName(props.filetype));
  } else {
    return getIcon(props.filetype);
  }
});
</script>
