<template>
  <div class="bg-slate-800 text-center text-2xl">
    <div class="p-2">
      <router-link to="/">
        <img src="./../../assets/images/logo.svg" />
      </router-link>
    </div>
    <MenuItem v-bind="routes.homeRoute" />
    <MenuItem v-bind="routes.calendarRoute" />
    <MenuItem v-bind="routes.financeRoute" v-if="canViewInvoice" />
    <MenuItem v-bind="routes.patientsRoute" v-if="canViewPatients" />
    <MenuItem
      v-bind="routes.healthcareProfessionalsRoute"
      v-if="canViewHealthcareProfessionals"
    />
    <MenuItem v-bind="routes.dataExplorer" v-if="canViewDataExplorer" />
    <MenuItem v-bind="routes.businessIntelligenceRoute" v-if="canViewBi" />
    <MenuItem v-bind="routes.adminRoute" />
  </div>
</template>

<script lang="ts" setup>
import MenuItem from "./MenuItem.vue";
import { hasFeatureFlag } from "@/libraries/plugins/hasFeatureFlag";
import { computed, ComputedRef, onBeforeUnmount, onMounted } from "vue";
import { hasPermission } from "@/libraries/utils/hasPermission";
import { routes } from "./sidenavRoutes";
import hotkeyManager from "@/libraries/utils/hotkeyManager";
import { useRouter } from "vue-router";
import { Hotkey } from "@/models/Hotkey";

const router = useRouter();

onMounted(() => {
  registerGlobalHotkeys();
});

onBeforeUnmount(() => {
  unregisterGlobalHotkeys();
});

const canViewPatients = computed(() => hasPermission("view-patient"));

const canViewHealthcareProfessionals = computed(() =>
  hasPermission("view-healthcare-professional"),
);

const canViewBi = computed(() => {
  if (
    hasPermission("view-business-intelligence-all") &&
    hasFeatureFlag("business-intelligence")
  ) {
    return true;
  }

  if (
    hasPermission("view-business-intelligence") &&
    hasFeatureFlag("business-intelligence-self")
  ) {
    return true;
  }
  return false;
});

const canViewDataExplorer = computed(() => {
  return hasPermission("view-data-explorer") && hasFeatureFlag("data-explorer");
});

const canViewInvoice = computed(() => {
  return hasPermission("view-invoice");
});

const globalHotkeys: {
  key: Hotkey;
  sym: symbol;
  action: () => void;
  permission: ComputedRef<boolean>;
}[] = [
  {
    key: { key: "G", secondKey: "D" },
    sym: Symbol(),
    action: () => router.push("/"),
    permission: canViewPatients,
  },
  {
    key: { key: "G", secondKey: "F" },
    sym: Symbol(),
    action: () => router.push("/finance"),
    permission: canViewInvoice,
  },
  {
    key: { key: "G", secondKey: "A" },
    sym: Symbol(),
    action: () => router.push("/calendar"),
    permission: computed(() => true),
  },
  {
    key: { key: "G", secondKey: "P" },
    sym: Symbol(),
    action: () => router.push("/patients"),
    permission: canViewPatients,
  },
  {
    key: { key: "G", secondKey: "H" },
    sym: Symbol(),
    action: () => router.push("/healthcare_professionals"),
    permission: canViewHealthcareProfessionals,
  },
  {
    key: { key: "G", secondKey: "G" },
    sym: Symbol(),
    action: () => router.push("/businessIntelligence"),
    permission: canViewBi,
  },
  {
    key: { key: "G", secondKey: "S" },
    sym: Symbol(),
    action: () => router.push("/admin"),
    permission: computed(() => true),
  },
];

function registerGlobalHotkeys() {
  globalHotkeys.forEach((hotkey) => {
    if (!hotkey.permission.value) {
      return;
    }
    hotkeyManager.registerHotkey(hotkey.key, hotkey.sym, hotkey.action);
  });
}

function unregisterGlobalHotkeys() {
  globalHotkeys.forEach((hotkey) =>
    hotkeyManager.unregisterHotkey(hotkey.key, hotkey.sym),
  );
}
</script>
