<template>
  <select
    class="border-box relative m-0 block appearance-none rounded border border-gray-300 bg-white py-2 pl-3 pr-3 shadow-sm transition duration-75 focus:border-blue-600 focus:outline-none focus:ring"
    v-model="modelValue"
    @change="emit('change', $event)"
    @blur="emit('blur', $event)"
    @keyup="emit('keyup', $event)"
    :class="[
      textSize,
      'border-box relative m-0 block appearance-none rounded border border-gray-300 bg-white text-sm shadow-sm transition duration-75 focus:border-blue-600 focus:outline-none focus:ring',
      getPadding(),
      error ? 'ring ring-red-400 focus:border-red-500' : '',
      disabled ? 'cursor-not-allowed bg-gray-100 text-gray-500' : '',
      fitContent ? 'w-auto' : 'w-full',
    ]"
    :disabled="disabled"
  >
    <option v-if="placeholder" :value="undefined" disabled hidden>
      {{ placeholder }}
    </option>
    <option v-if="placeholder" :value="null" disabled hidden>
      {{ placeholder }}
    </option>
    <slot></slot>
  </select>
</template>

<script setup lang="ts" generic="T">
import { TextSizes } from "@/libraries/UI/text";

const modelValue = defineModel<T | undefined>("modelValue", { required: true });

const props = withDefaults(
  defineProps<{
    placeholder?: string;
    icon?: string;
    error?: boolean;
    disabled?: boolean;
    fitContent?: boolean;
    textSize?: TextSizes;
    size?: "md" | "sm";
  }>(),
  {
    size: "md",
    fitContent: false,
    textSize: "text-sm",
  },
);

const emit = defineEmits<{
  (e: "change", val: Event): void;
  (e: "blur", val: FocusEvent): void;
  (e: "keyup", val: KeyboardEvent): void;
  (e: "update:modelValue", val?: T): void;
}>();

function getPadding(): string {
  switch (props.size) {
    case "md":
      return "py-2 pl-3 pr-3";
    case "sm":
      return "py-1 pl-3 pr-3";
  }
}

// onMounted(() => {
//   if (!import.meta.env.PROD) {
//     assertSelectOptionsHaveInnerText(document.querySelector('select')!);
//   }
// });

// function assertSelectOptionsHaveInnerText(select: HTMLSelectElement): void {
//   const children = Array.from(select.children) as HTMLElement[];

//   const options: HTMLOptionElement[] = children.filter(
//     (el): el is HTMLOptionElement => el.tagName == "OPTION",
//   );

//   if (options.some((option) => option.text.trim().length == 0)) {
//     throw new Error("Found option without text. Fix this for Safari.");
//   }
// }
</script>

<style scoped>
select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}
</style>
