<template>
  <div class="relative">
    <TypeAhead
      v-model="modelValue"
      icon="far fa-search"
      :typeahead="typeahead"
      :data="data"
      @select="emit('select', $event)"
      :placeholder="$t('general.search')"
      :focus-on-mount="true"
    >
      <template #results="{ data, activeIndex }">
        <ResultList :listData="data" :activeIndex="activeIndex">
          <template #entry="{ entry, index }">
            <a
              :class="[
                'group box-border flex w-full cursor-pointer items-center justify-between  px-3 py-2 text-sm transition duration-75',
                activeIndex === index
                  ? 'bg-slate-100 text-slate-700'
                  : 'text-slate-700 hover:bg-slate-100 active:text-slate-700',
              ]"
            >
              <span> {{ fullName(entry) }} </span>
              <div class="flex justify-end gap-4 text-xs text-slate-400">
                <span v-if="'inactive_at' in entry && entry.inactive_at"
                  >Inactive</span
                >
                <span v-if="entry.date_of_birth">{{
                  dateFilter(entry.date_of_birth)
                }}</span>
              </div>
            </a>
          </template>
        </ResultList>
      </template>
    </TypeAhead>
  </div>
</template>

<script lang="ts" setup generic="T extends Patient | PatientSearchResult">
import ResultList from "../ResultList.vue";
import TypeAhead from "./TypeAhead.vue";
import { fullName } from "@/models/Person";
import { dateFilter } from "@/filters";
import { Patient } from "@/models/Patient";
import { PatientSearchResult } from "@/libraries/repositories/patientRepository";

// Vue 3 migration check
const props = withDefaults(
  defineProps<{
    placeholder?: string;
    icon?: string;
    prefix?: string;
    disabled?: boolean;
    typeahead?: () => Promise<T[]>;
    data?: T[];
    debounceTimeout?: number;
    defaultData?: T[];
    autoSelect?: boolean;
    pattern?: string;
    regexPattern?: RegExp;
    focusOnMount?: boolean;
    combinedLength?: number;
  }>(),
  {
    debounceTimeout: 300,
    defaultData: () => [] as T[],
    autoSelect: false,
  },
);

const modelValue = defineModel<string>();

const emit = defineEmits<{
  (event: "select", value: { zis_number: number }): void;
  (event: "submit"): void;
}>();
</script>
