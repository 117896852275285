import { z } from "zod";
import { ColorStyleColor, colorStyles } from "./ColorStyles";

export interface PatientLabelForm {
  id?: number;
  name: string;
  color: ColorStyleColor;
}

export const patientLabelSchema = z.object({
  id: z.number(),
  name: z.string(),
  color: z.string().refine((arg): arg is ColorStyleColor => arg in colorStyles),
  created_at: z.string().transform((s) => new Date(s)),
  updated_at: z.string().transform((s) => new Date(s)),
});

export type PatientLabel = z.infer<typeof patientLabelSchema>;
