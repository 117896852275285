import { GenderType } from "./Patient";
import { ReferralPersisted } from "./Referral";
import { EmailSent } from "./EmailSent";
import { User } from "./User";
import { Location } from "@/models/Location";
import moment from "moment";
import { ColorStyleColor } from "./ColorStyles";

export interface AppointmentType {
  id: number;
  name: string;
  duration: number;
  style: ColorStyleColor;
  online_scheduling: boolean;
  slug: string;
  deleted_at?: string;
  private?: boolean;
  house_call: boolean;
  first_consult: boolean;
  is_complex: boolean;
  is_instruction: boolean;
  is_one_time_examination: boolean;
  medium_type: AppointmentMediumType;
  description: string;
  locations: Location[];
  product_ids?: string[];
  therapy_type?: AppointmentTherapyType;
  package_uuid?: string;
  amount_credits: number;
  should_invoice: boolean;
  invoice_generation_data?: {
    first_consult_without_consult: boolean;
    no_show_product_id?: string;
  };
}

export interface AppointmentTypeForm {
  name: string;
  duration: number;
  style: ColorStyleColor;
  house_call: boolean;
  first_consult: boolean;
  is_complex: boolean;
  is_instruction: boolean;
  is_one_time_examination: boolean;
  group_appointment?: boolean;
  description: string;
  locations: number[];
  product_ids: string[];
  therapy_type?: AppointmentTherapyType;
  package_uuid?: string;
  amount_credits: number;
  should_invoice: boolean;
  medium_type: AppointmentMediumType;
  invoice_generation_data: {
    first_consult_without_consult: boolean;
    no_show_product_id?: string;
  };
}

export const appointmentTherapyTypes = [
  "physiotherapy",
  "manual_therapy",
  "podiatry",
  "speech_therapy",
  "preverbal_speech_therapy",
  "stutter_speech_therapy",
  "childrens_physiotherapy",
  "edema_therapy",
  "hapto_therapy",
] as const;

export const appointmentMediumTypes = [
  "clinic",
  "house_call",
  "phone",
  "online",
  "institution",
] as const;

export type AppointmentMediumType = (typeof appointmentMediumTypes)[number];

export type AppointmentTherapyType = (typeof appointmentTherapyTypes)[number];

export type AppointmentSuggestion = {
  start: moment.Moment;
  end: moment.Moment;
  appointment_type_id?: number;
  participants?: { user_id: number; status: AppointmentParticipantStatus }[];
};

export const appointmentStatus = [
  "BOOKED",
  "PROPOSED",
  "PENDING",
  "ARRIVED",
  "FULFILLED",
  "CANCELLED",
  "NOSHOW",
  "ENTERED-IN-ERROR",
] as const;

export type AppointmentStatus = (typeof appointmentStatus)[number];
export const appointmentParticipantStatus = [
  "ACCEPTED",
  "DECLINED",
  "TENTATIVE",
  "NEEDS-ACTION",
] as const;
export type AppointmentParticipantStatus =
  (typeof appointmentParticipantStatus)[number];

export const appointmentParticipantRequired = [
  "REQUIRED",
  "OPTIONAL",
  "INFORMATION-ONLY",
] as const;
export type AppointmentParticipantRequired =
  (typeof appointmentParticipantRequired)[number];

export interface Appointment {
  id: number;
  status: AppointmentStatus;
  appointment_type_id?: number;
  description?: string;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
  start: moment.Moment;
  end: moment.Moment;
  participants: (Participant | UnpersistedParticipant)[];
  email_sents: EmailSent[];
  comment?: string;
  private?: boolean;
  referral_id?: number;
  referral?: ReferralPersisted;
  metadata?: { request: MetadataRequest };
  uuid: string;
  locked?: boolean;
  repeating_group?: {
    uuid: string;
    interval: "week";
    interval_multiplier: number;
  };
}

export interface MetadataRequest {
  ip: string;
  date: string;
  user: string;
  timeslot: string;
  first_names: string;
  surname_prefix: string;
  surname: string;
  date_of_birth: string;
  email_address: string;
  phone_number: string;
  appointment_type: string;
  gender: GenderType;
}

export type AnyAppointment =
  | Appointment
  | UnpersistedAppointment
  | AppointmentWithColumn;

export type AppointmentProposal = Appointment & {
  status: "PROPOSED";
  metadata: { request: MetadataRequest };
};

export interface UnpersistedAppointment {
  status: AppointmentStatus;
  appointment_type_id?: number;
  description?: string;
  start: moment.Moment;
  end: moment.Moment;
  participants: UnpersistedParticipant[];
  email_sents: EmailSent[];
  comment?: string;
  private?: boolean;
  referral_id?: number;
}
export interface CopiedAppointment {
  status: AppointmentStatus;
  appointment_type_id?: number;
  description?: string;
  participants: UnpersistedParticipant[];
  email_sents: EmailSent[];
  comment?: string;
  private?: boolean;
  referral_id?: number;
  duration?: number;
  end?: moment.Moment;
}

export type HiddenAppointment = Appointment & {
  private: true;
  description?: undefined;
  comment?: undefined;
  appointment_type_id?: undefined;
};

export interface StoreAppointmentPayload {
  participants: UnpersistedParticipant[];
  status: AppointmentStatus;
  appointment_type_id?: number;
  description?: string;
  start: string;
  end: string;
  private?: boolean;
  referral_id?: number;
  suppress_email?: boolean;
  cut_appointment?: boolean;
  repeat_interval_unit?: "week";
  repeat_interval_multiplier?: number;
  number_of_repeats?: number;
  questionnaire_response_request_schema_template_uuid?: string;
}

export interface UnpersistedParticipant {
  user_id?: number;
  responsible_user_id?: number;
  patient_zis_number?: number;
  location_id?: number;
  company_division_id?: string;
  status: AppointmentParticipantStatus;
  required?: AppointmentParticipantRequired;
}

export type Participant = {
  id: number;
  appointment_id: number;
  user_id?: number | undefined;
  responsible_user_id?: number | undefined;
  patient_zis_number?: number | undefined;
  location_id?: number | undefined;
  company_division_id?: string | undefined;
  status: AppointmentParticipantStatus;
  required?: AppointmentParticipantRequired | undefined;
};

type AnyParticipant = Participant | UnpersistedParticipant;

export type PatientParticipant = AnyParticipant & {
  patient_zis_number: number;
};
export type UserParticipant = AnyParticipant & { user_id: number };
export type ResponsibleUserParticipant = AnyParticipant & {
  responsible_user_id: number;
};
export type LocationParticipant = AnyParticipant & { location_id: number };
export type CompanyParticipant = AnyParticipant & {
  company_division_id: number;
};

export function isPersisted(
  appointment: AnyAppointment,
): appointment is Appointment {
  return !!(appointment as Appointment).id;
}

export function isPatientParticipant(
  participant: AnyParticipant,
): participant is PatientParticipant {
  return participant.patient_zis_number !== undefined;
}

export function getPatientParticipants(appointment: {
  participants?: UnpersistedParticipant[] | Participant[];
}): PatientParticipant[] {
  return appointment.participants
    ? (appointment.participants.filter(
        isPatientParticipant,
      ) as PatientParticipant[])
    : [];
}

export function isUserParticipant(
  participant: AnyParticipant,
): participant is UserParticipant {
  return participant.user_id !== undefined;
}

export function getUserParticipants(appointment: {
  participants?: UnpersistedParticipant[] | Participant[];
}): UserParticipant[] {
  return appointment.participants
    ? (appointment.participants.filter(isUserParticipant) as UserParticipant[])
    : [];
}

export function isResponsibleUserParticipant(
  participant: AnyParticipant,
): participant is ResponsibleUserParticipant {
  return participant.responsible_user_id !== undefined;
}

export function getResponsibleUserParticipants(appointment: {
  participants?: UnpersistedParticipant[] | Participant[];
}): ResponsibleUserParticipant[] {
  return appointment.participants
    ? (appointment.participants.filter(
        isResponsibleUserParticipant,
      ) as ResponsibleUserParticipant[])
    : [];
}

export function isLocationParticipant(
  participant: AnyParticipant,
): participant is LocationParticipant {
  return participant.location_id !== undefined;
}

export function getLocationParticipants(appointment: {
  participants?: UnpersistedParticipant[] | Participant[];
}): LocationParticipant[] {
  return appointment.participants
    ? (appointment.participants.filter(
        isLocationParticipant,
      ) as LocationParticipant[])
    : [];
}

export function isCompanyParticipant(
  participant: AnyParticipant,
): participant is CompanyParticipant {
  return participant.company_division_id !== undefined;
}
export function getCompanyParticipants(appointment: {
  participants?: UnpersistedParticipant[] | Participant[];
}): CompanyParticipant[] {
  return appointment.participants
    ? (appointment.participants.filter(
        isCompanyParticipant,
      ) as CompanyParticipant[])
    : [];
}

export function isAppointmentWithColumn(
  appointment: AnyAppointment,
): appointment is AppointmentWithColumn {
  const app = appointment as AppointmentWithColumn;
  return !!app.column && !!app.total_columns && !!app.width;
}

export function isUserParticipating(
  user: User,
  appointment: AnyAppointment,
): boolean {
  return appointment.participants.some(
    (participant) => participant.user_id === user.id,
  );
}

export function abbreviateAppointmentType(type: AppointmentType) {
  const nameArray = type.name.split(" ");
  if (nameArray.length === 1) {
    return nameArray[0].substring(0, 2);
  }
  let initials = "";
  nameArray.forEach((str) => (initials += str.substring(0, 1).toUpperCase()));
  return initials;
}

export type AppointmentWithColumn = Appointment & {
  column: number;
  total_columns: number;
  width: number;
};
