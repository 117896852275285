import { apiClient } from "@/libraries/utils/axios";
import { handledZodParse, handleErrors } from "@/libraries/utils/errorHandling";
import { InjectionKey } from "vue";
import { z } from "zod";
import { carePlanSchema } from "../CarePlan/CarePlan";
import { invoiceTypes } from "@/apis/patients/invoices";

export const referralInvoiceSettingsSchema = z.object({
  default_invoice_type: z.enum(invoiceTypes).optional(),
});

const UserSchema = z.object({
  id: z.number(),
  first_names: z.string(),
  surname_prefix: z.optional(z.string()),
  surname: z.optional(z.string()),
  initials: z.string(),
});

const chronicPeriodSchema = z.object({
  start_date: z.string().optional(),
  end_date: z.string().optional(),
  number_of_treatments: z.number().optional(),
  default_csi_code: z.string().optional(),
});

const referralSchema = z.object({
  id: z.number().optional(),
  patient_zis_number: z.number().optional(),
  hcp_diagnosis: z.string(),
  diagnosis_code: z.string().optional(),
  hcp_lpdf_id: z.number().optional(),
  hcp_lpdf_name: z.string().optional(),
  referred_at: z.string().optional(),
  referrer_diagnosis_code: z.string().optional(),
  default_csi_code: z.string().optional(),
  is_chronic: z.boolean().optional(),
  chronic_periods: z.array(chronicPeriodSchema).optional(),
  is_self_referral: z.boolean(),
  AGB_code: z.string().optional(),
  specialism_code: z.string().optional(),
  machtiging_code: z.string().optional(),
  location_indication: z.string().optional(),
  invoice_settings: referralInvoiceSettingsSchema.optional(),
  created_at: z.string().optional(),
  updated_at: z.string().optional(),
});

const referralPersistedSchema = referralSchema.extend({
  id: z.number(),
  patient_zis_number: z.number(),
  care_plans: z.array(carePlanSchema).optional(),
  main_therapist: UserSchema.optional(),
  treatments: z.array(z.any()).optional(),
  closed_at: z.string().optional(),
  created_at: z.string(),
  updated_at: z.string(),
});

const undeclarablePatientReferrals = referralSchema.extend({
  number_of_referrals: z.number(),
  referral_name: z.string(),
  first_names: z.string(),
  surname_prefix: z.string(),
  surname: z.string(),
  zis_number: z.number(),
});

export type ChronicPeriod = ReturnType<(typeof chronicPeriodSchema)["parse"]>;

export type ReferralInvoiceSettings = ReturnType<
  (typeof referralInvoiceSettingsSchema)["parse"]
>;

export type Referral = ReturnType<(typeof referralSchema)["parse"]>;

export type UndeclarablePatientReferrals = ReturnType<
  (typeof undeclarablePatientReferrals)["parse"]
>;

export type ReferralPersisted = ReturnType<
  (typeof referralPersistedSchema)["parse"]
>;

export const ReferralRepositoryKey = Symbol() as InjectionKey<
  () => ReferralRepository
>;

export interface ReferralRepository {
  save(
    patientZisNumber: number,
    payload: (Referral | ReferralPersisted) & {
      questionnaire_response_request_schema_template_uuid?: string;
      ignore_questionnaire_schema_creation_item?: boolean;
    },
  ): Promise<ReferralPersisted>;
  updateChronicPeriods(
    zisNumber: number,
    referralId: number,
    chronicPeriods: ChronicPeriod[],
  ): Promise<ReferralPersisted>;
}

export class ReferralRepositoryUsingApi implements ReferralRepository {
  async save(
    zisNumber: number,
    referral: (Referral | ReferralPersisted) & {
      questionnaire_response_request_schema_template_uuid?: string;
    },
  ): Promise<ReferralPersisted> {
    referral = formatChronicPeriods(referral);
    try {
      if (referralIsPersisted(referral)) {
        return await update(zisNumber, referral);
      } else {
        return await create(zisNumber, referral);
      }
    } catch (error) {
      handleErrors({ error });
    }
  }

  async updateChronicPeriods(
    zisNumber: number,
    referralId: number,
    chronicPeriods: ChronicPeriod[],
  ) {
    let referral = {
      id: referralId,
      is_chronic: true,
      chronic_periods: chronicPeriods,
    };
    referral = formatChronicPeriods(referral);
    return await update(zisNumber, referral);
  }
}

function referralIsPersisted(
  referral: Referral | ReferralPersisted,
): referral is ReferralPersisted {
  return "id" in referral && typeof referral.id === "number";
}

async function create(zisNumber: number, referral: Referral) {
  const { data } = await apiClient.post(
    `/patients/${zisNumber}/referrals`,
    referral,
  );
  return handledZodParse({
    schema: referralPersistedSchema,
    input: data.referral,
    notifyUser: false,
    reportError: true,
    throwOnFail: false,
  });
}

async function update(zisNumber: number, referral: Partial<ReferralPersisted>) {
  const { data } = await apiClient.patch(
    `/patients/${zisNumber}/referrals/${referral.id}`,
    referral,
  );
  return handledZodParse({
    schema: referralPersistedSchema,
    input: data.referral,
    notifyUser: false,
    reportError: true,
    throwOnFail: false,
  });
}

function formatChronicPeriods<T>(
  referral: T & { chronic_periods?: ChronicPeriod[] },
): T {
  referral.chronic_periods?.forEach((period) => {
    period.number_of_treatments = Number(period.number_of_treatments);
  });
  return referral;
}
