<template>
  <div style="scroll-margin: 1rem" ref="root">
    <label
      class="mb-2 mr-2 block self-center"
      v-if="item.text != null"
      :for="'b_' + uuid"
      >{{ item.text }}</label
    >
    <div
      class="inline-flex flex-wrap gap-x-3 gap-y-2"
      v-if="item.answerOption.length < 10 || multiple"
      :class="[orientation === 'row' ? 'flex-row' : 'flex-col']"
    >
      <label
        v-for="(answer, index) in item.answerOption"
        :key="index"
        :class="[
          'inline-flex select-none items-center space-x-2 rounded border border-gray-300 px-2 py-1 text-sm transition duration-75 focus-within:ring',
          readonly
            ? answerIndex == index || answerIndices[index]
              ? ''
              : 'opacity-25'
            : 'cursor-pointer focus-within:border-blue-600 hover:border-gray-500',
        ]"
      >
        <input
          v-if="multiple && !readonly"
          v-model="answerIndices[index]"
          type="checkbox"
          :name="uuid"
          :value="index"
          @keyup="scrollToQuestion"
          :style="{
            'background-image': backgroundImageMultiple,
          }"
          :class="[
            'm-0 h-4 w-4 flex-shrink-0 cursor-pointer appearance-none border border-gray-500 bg-gray-50 transition duration-75 checked:border-transparent checked:bg-blue-600 focus:outline-none focus:ring-0',
            multiple ? 'rounded' : 'rounded-full',
          ]"
        />
        <input
          v-else-if="!readonly"
          v-model="answerIndex"
          type="radio"
          :name="uuid"
          :value="index"
          @keyup="scrollToQuestion"
          :style="{
            'background-image': backgroundImage,
          }"
          :class="[
            'm-0 h-4 w-4 flex-shrink-0 cursor-pointer appearance-none border border-gray-500 bg-gray-50 transition duration-75 checked:border-transparent checked:bg-blue-600 focus:outline-none focus:ring-0',
            multiple ? 'rounded' : 'rounded-full',
          ]"
        />
        <span
          class="pointer-events-none max-w-prose select-none slashed-zero"
          >{{ getDisplay(answer) }}</span
        >
      </label>
    </div>
    <div v-else>
      <div class="relative inline-block">
        <div
          class="block-inline rounded border border-gray-400 bg-white px-4 py-2 pr-8 text-sm"
          v-if="readonly && answerIndex !== undefined"
        >
          {{ getDisplay(item.answerOption[answerIndex]) }}
        </div>
        <select
          class="block w-full appearance-none rounded border border-gray-400 bg-white px-4 py-2 pr-8 text-sm outline-none hover:border-gray-500 focus:border-blue-600 focus:ring"
          v-else
          v-model="answerIndex"
          :id="'b_' + uuid"
        >
          <option
            v-for="(answer, index) in item.answerOption"
            :key="index"
            :value="index"
          >
            {{ getDisplay(answer) }}
          </option>
        </select>
        <div
          class="pointer-events-none absolute bottom-0 right-0 top-0 flex items-center px-2 text-gray-700"
        >
          <svg
            class="h-4 w-4 fill-current"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path
              d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup generic="T extends Coding">
import {
  ChoiceItem,
  Coding,
  EXTENSION,
  getFirstCode,
  ResponseForm,
  VALUE_SET,
} from "@/libraries/questionnaires/item";
import { isCoding } from "@/libraries/questionnaires/score";
import { computed, onMounted, ref, watch } from "vue";

const uuid = crypto.randomUUID();

const defaultOrientation = "row";

const props = withDefaults(
  defineProps<{
    item: ChoiceItem<T>;
    response: ResponseForm;
    readonly?: boolean;
    label?: string;
  }>(),
  {
    readonly: false,
  },
);

const answerIndex = ref<number>();
const answerIndices = ref<{ [key: string]: boolean }>({});

const multiple = computed(() => props.item?.repeats === true);

const root = ref<HTMLDivElement | null>(null);

const backgroundImage =
  "url(\"data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e\")";

const backgroundImageMultiple =
  "url(\"data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e\")";

watch(answerIndex, () => {
  if (answerIndex.value === undefined) {
    return;
  }

  props.response[props.item.linkId] =
    props.item.answerOption[answerIndex.value];
});

watch(
  answerIndices,
  () => {
    const answers = [];
    for (const [index, checked] of Object.entries(answerIndices.value)) {
      const idx = parseInt(index, 10);
      if (checked && Number.isSafeInteger(idx)) {
        const value = props.item.answerOption[idx];
        answers.push(value);
      }
    }
    props.response[props.item.linkId] = answers;
  },
  { deep: true },
);

onMounted(() => {
  if (!(props.item.linkId in props.response)) {
    return;
  }

  handleInitialResponse();
});

const orientation = computed(() => {
  const orientation = getFirstCode(props.item, {
    url: EXTENSION.CHOICE_ORIENTATION,
    system: VALUE_SET.ORIENTATION,
  });

  if (typeof orientation !== "string") {
    return defaultOrientation;
  }

  if (orientation === "column" || orientation === "row") {
    return orientation;
  }

  throw new Error(orientation);
});

function handleInitialResponse() {
  const response = props.response[props.item.linkId];

  if (response === null) {
    return;
  }

  if (multiple.value) {
    handleInitialMultipleChoice(response);
  } else {
    handleInitialSingleChoice(response);
  }
}

function handleInitialMultipleChoice(
  response: NonNullable<ResponseForm[string]>,
) {
  if (!responseIsValueCodingArray(response)) {
    throw new Error("Response is not a valueCoding array.");
  }

  props.item.answerOption.forEach((option, index) => {
    answerIndices.value[index] = response.some(
      ({ valueCoding: code }: { valueCoding: Coding }) => {
        return option.valueCoding.display == code.display;
      },
    );
  });
}

function handleInitialSingleChoice(
  response: NonNullable<ResponseForm[string]>,
) {
  if (!isCoding(response)) {
    throw new Error("Expected coding, got " + typeof response);
  }

  answerIndex.value = responseItemToIndex(response);
}

function responseIsValueCodingArray(
  response: ResponseForm[string],
): response is Array<{ valueCoding: Coding }> {
  if (!Array.isArray(response)) {
    return false;
  }

  if (response.some((val: any) => !isCoding(val))) {
    return false;
  }

  return true;
}

function responseItemToIndex({
  valueCoding: code,
}: {
  valueCoding: Coding;
}): number {
  return props.item.answerOption.findIndex((option) => {
    return option.valueCoding.display == code.display;
  });
}

function getDisplay(answer: ChoiceItem<T>["answerOption"]): string {
  if (isCoding(answer)) {
    const display =
      answer.valueCoding.display ?? answer.valueCoding.code?.toString();
    if (typeof display !== "string") {
      throw new Error("coding has no display or code.");
    }
    return display;
  }

  throw new Error("unknown answer");
}

function scrollToQuestion(ev: KeyboardEvent) {
  if (ev.code == "Tab")
    root.value?.scrollIntoView({
      block: "nearest",
      inline: "nearest",
    });
}
</script>
