/**
 * Check if file is below certain size `maxSize`.
 *
 * @param file File
 * @param maxSize Max size of file in bytes
 */
export async function fileIsCorrectSize(
  file: File,
  maxSize: number,
): Promise<boolean> {
  const buffer = await readFile(file, "ArrayBuffer");
  return buffer.byteLength <= maxSize;
}

export function readFile<
  T extends "ArrayBuffer" | "DataURL" | "Text" | "Binary",
>(
  file: File,
  type: T,
): Promise<T extends "ArrayBuffer" ? ArrayBuffer : string> {
  const filereader = new FileReader();
  if (type == "ArrayBuffer") {
    filereader.readAsArrayBuffer(file);
  } else if (type == "Binary") {
    filereader.readAsBinaryString(file);
  } else if (type == "DataURL") {
    filereader.readAsDataURL(file);
  } else {
    filereader.readAsText(file);
  }

  return new Promise((resolve, reject) => {
    filereader.onload = (event) => {
      if (filereader.result == null) {
        throw new Error("result is null");
      }
      resolve(filereader.result as any);
    };
  });
}

export function fileHasValidExtension(file: File, extensions: string[]) {
  const extension = getFileExtension(file);
  return extensions.includes(extension);
}

/**
 * Get the file extension. If file has no extension an empty string is returned.
 *
 * @param file File
 */
export function getFileExtension(file: File): string {
  return getFileExtensionFromName(file.name);
}

export function getFileExtensionFromName(name: string): string {
  const split = name.split(".");
  if (split.length == 0) {
    return "";
  }
  return split[split.length - 1];
}

export function getFileMimeType(file: File): string {
  return file.type ?? extensionToMimeType(getFileExtension(file));
}

const mimes = new Map(
  Object.entries({
    csv: "text/csv",
    png: "image/png",
    jpg: "image/jpg",
    jpeg: "image/jpeg",
    pdf: "application/pdf",
    txt: "text/plain",
    xls: "application/vnd.ms-excel",
    xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  }),
);
export function extensionToMimeType(extension: string): string {
  return mimes.get(extension) ?? "application/octet-stream";
}
