import { z } from "zod";

export type DocumentState = "waiting" | "temporary" | "moving" | "definite";

export type Document = z.infer<typeof documentSchema>;

export const documentSchema = z.object({
  id: z.number(),
  filename: z.string(),
  content_type: z.string(),
  state: z.enum(["waiting", "temporary", "moving", "definite"]),
  temporary_uuid: z.string(),
  size: z.number().optional(),
  created_at: z.string().transform((x) => new Date(x)),
  updated_at: z.string().transform((x) => new Date(x)),
});
